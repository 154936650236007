import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Candidatebox as Candidate,
  Shortlist,
  Rejected,
  Selected,
  Applied,
} from "./Candidatebox";
import {
  MCandidatebox as MCandidate,
  MShortlist,
  MRejected,
  MSelected,
} from "./MCandidatebox";
import "../../styles/candidate.scss";
import ChevronLeftTwoToneIcon from "@mui/icons-material/ChevronLeftTwoTone";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import Filter from "./Filter";
import Tweak from "../Momatch-Tweak/Tweak";
import { k_url, url } from "../../util/data/static-data";
import ViewJob from "../Jobs/ViewJob";
import Loading from "../Loading/Loading";

function Candidateslist() {
  const history = useHistory();

  const observer = useRef();

  const [forclient, setforclient] = useState(false);

  const [rerunmomatch, setrerunmomatch] = useState(false);
  const [moformdata, setmoformdata] = useState({});

  const [isloading, setisloading] = useState(false);
  const [hasmore, sethasmore] = useState(false);
  const [loadmore, setloadmore] = useState(false);
  const [page_no, setpage_no] = useState(1);

  const [stopload, setstopload] = useState(true);
  const [error, seterror] = useState(false);
  const [update, setupdate] = useState(true);

  const [jid] = useState(JSON.parse(localStorage.getItem("a_j_id")));
  const [cid] = useState(JSON.parse(localStorage.getItem("a_c_id")));
  const [aid] = useState(JSON.parse(localStorage.getItem("id")));

  const [candidates, setcandidates] = useState([]);

  const [candidate_type, setcandidate_type] = useState(false);
  const [def, setdef] = useState(true);

  const [mcandidate_type, setmcandidate_type] = useState(false);
  const [mdef, setmdef] = useState(true);
  const [mapplied, setmapplied] = useState(false);
  const [mwithdrawn, setmwithdrawn] = useState(false);

  const [filter, setfilter] = useState(null);
  const [changebtn, setchangebtn] = useState(true);
  const [changed, setchanged] = useState(true);
  const [count, setcount] = useState(0);

  const [jobdata, setjobdata] = useState({});

  const [overlay, setoverlay] = useState(false);
  const [viewclicked, setviewclicked] = useState(false);

  const [curcount, setcurcount] = useState(0);
  const [nrml, setnrml] = useState([]);
  const [rejected, setrejected] = useState([]);
  const [selected, setselected] = useState([]);
  const [applied, setapplied] = useState([]);
  const [withdrawn, setwithdrawn] = useState([]);
  const [shortlisted, setshortlisted] = useState([]);
  const [maildata, setmaildata] = useState({
    company: "",
    username: "",
    jobtittle: "",
    joblink: "",
    city: "",
    country: "",
    level: "",
    jobcode: "",
  });

  const lastelementref = useCallback(
    (node) => {
      if (isloading || filter !== null) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((e) => {
        if (e[0].isIntersecting && hasmore) {
          setloadmore(true);
          setchanged(true);
          setpage_no((prev) => prev + 1);
        } else setloadmore(false);
      });
      if (node) observer.current.observe(node);
    },
    [isloading, hasmore, filter],
  );

  useEffect(() => {
    if (cid !== "") {
      fetch(k_url + "/company/find", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: cid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (jid !== "") {
            fetch(`${k_url}/job/find`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                _id: jid,
              }),
            })
              .then((response) => response.json())
              .then((res) => {
                if (res.success) {
                  setmaildata({
                    company: data.result.company,
                    username: data.result.name,
                    jobtittle: res.result.jobTitle,
                    joblink: res.result.jobUrl,
                    city: res.result.city,
                    country: res.result.country,
                    level: res.result.careerLevel,
                    jobcode: res.result.jobCode,
                  });
                  setjobdata(res.result);
                }
              })
              .catch(() => console.log("error fetching data"));
          }
        });
    }
  }, [jid, cid]);

  useEffect(() => {
    if (hasmore && curcount <= 0) {
      setisloading(true);
      setpage_no((nxt) => nxt + 1);
    }
  }, [hasmore, curcount, candidates, shortlisted, rejected]);

  const clientref = useRef(forclient);

  useEffect(() => {
    if (clientref.current !== forclient) {
      setcandidates([]);
      setnrml([]);
      setrejected([]);
      setshortlisted([]);
      setselected([]);
      setpage_no(1);
    }
    clientref.current = forclient;
  }, [forclient]);

  useEffect(() => {
    if (jid !== "" && changed) {
      if (forclient) {
        setisloading(true);
        fetch(url + "/momatch", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jobid: jid,
            timestamp: new Date(),
            page: page_no,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.exists || data.success) {
              fetch(url + "/momatch/" + jid + "/" + page_no,{
                headers: { "M_AUTH_KEY": JSON.parse(localStorage.getItem("a_j_token")) },
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data.success) {
                    setcandidates((arr) => [
                      ...[...arr, ...data.data]
                        .reduce((map, obj) => map.set(obj._id, obj), new Map())
                        .values(),
                    ]);
                    sethasmore(data.data.length > 0);
                    setshortlisted([...new Set(data.shortlisted)]);
                    setrejected([...new Set(data.reject)]);
                    setselected([...new Set(data.hired)]);
                    setloadmore(false);
                    setisloading(false);
                    setstopload(false);
                    setupdate(false);
                  }
                })
                .catch(() => setisloading(false));
            } else {
              seterror(true);
            }
          })
          .catch(() => setisloading(false));
      } else {
        setisloading(true);
        fetch(url + "/admin/fetchjob", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jobid: jid,
            admin_id: aid,
            page: page_no,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success && data.formdata.Skills !== undefined) {
              fetch(url + "/admin/momatch", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  jobid: jid,
                  data: data.formdata,
                  timestamp: new Date(),
                  page: page_no,
                }),
              })
                .then((response) => response.json())
                .then((resdata) => {
                  if (resdata.exists || resdata.success) {
                    setcandidates((arr) => [
                      ...[...arr, ...data.candidates]
                        .reduce((map, obj) => map.set(obj._id, obj), new Map())
                        .values(),
                    ]);
                    sethasmore(data.candidates.length > 0);
                    setmoformdata(data.formdata);
                    setshortlisted([...new Set(data.shortlisted)]);
                    setrejected([...new Set(data.rejected)]);
                    setselected([...new Set(data.recommended)]);
                    setapplied([...new Set(data.applied)]);
                    setwithdrawn([...new Set(data.withdrawn)]);
                    setloadmore(false);
                    setisloading(false);
                    setstopload(false);
                    setupdate(false);
                    setrerunmomatch(false);
                    setchanged(false);
                  } else {
                    seterror(true);
                    setchanged(false);
                  }
                })
                .catch(() => setisloading(false));
            } else {
              setrerunmomatch(true);
            }
          })
          .catch(() => setisloading(false));
      }
    } // eslint-disable-next-line
  }, [
    jid,
    aid,
    page_no,
    mcandidate_type,
    mdef,
    forclient,
    moformdata,
    changed,
  ]);

  useEffect(() => {
    if (!rerunmomatch && jobdata.Slills === undefined) return;
    setisloading(true);
    setstopload(true);
    fetch(url + "/admin/momatch", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        jobid: jid,
        data: moformdata.Skills === undefined ? jobdata : moformdata,
        timestamp: new Date(),
        page: page_no,
      }),
    })
      .then((response) => response.json())
      .then((resdata) => {
        if (resdata.exists || resdata.success) {
          fetch(url + "/admin/fetchjob", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              jobid: jid,
              admin_id: aid,
              page: page_no,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success && data.formdata.Skills !== undefined) {
                setcandidates((arr) => [
                  ...[...arr, ...data.candidates]
                    .reduce((map, obj) => map.set(obj._id, obj), new Map())
                    .values(),
                ]);
                sethasmore(data.candidates.length > 0);
                setmoformdata(data.formdata);
                setshortlisted([...new Set(data.shortlisted)]);
                setrejected([...new Set(data.rejected)]);
                setselected([...new Set(data.recommended)]);
                setapplied([...new Set(data.applied)]);
                setwithdrawn([...new Set(data.withdrawn)]);
                setloadmore(false);
                setisloading(false);
                setstopload(false);
                setupdate(false);
                setrerunmomatch(false);
                setchanged(false);
              } else {
                seterror(true);
                setchanged(false);
              }
            });
        } else {
          seterror(true);
        }
      })
      .catch(() => setisloading(false));
  }, [rerunmomatch, moformdata, jid, page_no, aid, jobdata]);

  useEffect(() => {
    if (candidates.length > 0) {
      const arr = [];
      shortlisted !== undefined
        ? shortlisted.map((val) => arr.push(val))
        : console.info("No Shortlisted");
      selected !== undefined
        ? selected.map((val) => arr.push(val))
        : console.info("No Selected");
      rejected !== undefined
        ? rejected.map((val) => arr.push(val))
        : console.info("No Rejected");
      applied !== undefined
        ? applied.map((val) => arr.push(val))
        : console.info("No Applied");
      withdrawn !== undefined
        ? withdrawn.map((val) => arr.push(val))
        : console.info("No Withdrawn");
      arr.includes();
      setnrml([...new Set(candidates.filter((val) => !arr.includes(val._id)))]);
      setcurcount(
        [...new Set(candidates.filter((val) => !arr.includes(val._id)))].length,
      );
    }
  }, [candidates, selected, shortlisted, rejected, applied, withdrawn]);

  useEffect(() => {
    changebtn
      ? mcandidate_type && mdef
        ? setcount(selected.length)
        : mdef
        ? setcount(nrml.length)
        : mcandidate_type
        ? setcount(shortlisted.length)
        : mapplied
        ? setcount(applied.length)
        : mwithdrawn
        ? setcount(withdrawn.length)
        : setcount(rejected.length)
      : candidate_type && def
      ? setcount(selected.length)
      : def
      ? setcount(nrml.length)
      : candidate_type
      ? setcount(shortlisted.length)
      : setcount(rejected.length);
  }, [
    selected,
    def,
    candidate_type,
    nrml,
    rejected,
    shortlisted,
    applied,
    changebtn,
    mdef,
    mcandidate_type,
    mapplied,
    withdrawn,
    mwithdrawn,
  ]);

  // filter candidates
  useEffect(() => {
    setisloading(true);
    setstopload(true);
    if (!filter) {
      setchanged(true);
      return;
    }

    fetch(url + "/admin/search", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filter),
    })
      .then((response) => response.json())
      .then((resdata) => {
        const data = resdata.candidates;
        if (data !== undefined && data.length > 0) {
          setcandidates(data);
          setisloading(false);
          setstopload(false);
        } else {
          setcandidates([]);
          setisloading(false);
          setstopload(false);
        }
      })
      .catch((e) => seterror(true));
  }, [filter]);

  const defaultfunc = (val1, val2, val) => {
    setforclient(val);
    setupdate(true);
    val2(false);
    val1(true);
    setmapplied(false);
    setmwithdrawn(false);
    setchanged(true);
  };

  const appliedfunc = (val1, val2, val) => {
    setforclient(val);
    setupdate(true);
    val2(false);
    val1(false);
    setmapplied(true);
    setmwithdrawn(false);
    setchanged(true);
  };

  const withfunc = (val1, val2, val) => {
    setforclient(val);
    setupdate(true);
    val2(false);
    val1(false);
    setmapplied(false);
    setmwithdrawn(true);
    setchanged(true);
  };

  const shortlist = (val1, val2, val) => {
    setforclient(val);
    setupdate(true);
    setmapplied(false);
    setmwithdrawn(false);
    val2(true);
    val1(false);
    setchanged(true);
  };

  const selectfun = (val1, val2, val) => {
    setforclient(val);
    setupdate(true);
    setmwithdrawn(false);
    setmapplied(false);
    val2(true);
    val1(true);
    setchanged(true);
  };

  const reject = (val1, val2, val) => {
    setforclient(val);
    setupdate(true);
    setmapplied(false);
    setmwithdrawn(false);
    val2(false);
    val1(false);
    setchanged(true);
  };

  if (error) {
    return (
      <React.Fragment>
        <div className="flex flex-column items-center justify-center bg-white ma3 br2 vh-75">
          <p className="f3 gray">Something Went Wrong!</p>
          <button
            className="mt4 fw6 f6 bn dim br1 ph3 pointer pv2 dib white"
            style={{ background: "#6EB6FF" }}
            onClick={() => history.push("/dashboard")}
          >
            Go Back
          </button>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div style={{ background: "#eef2f5" }} className="flex-1 w-100 pa4-l pa3">
      <div
        onClick={() => {
          setviewclicked(false);
          setoverlay(false);
        }}
        className={`fixed overlay top-0 bottom-0 left-0 right-0 ${
          overlay ? "active" : ""
        }`}
      ></div>
      {viewclicked && (
        <div
          style={{ top: "6rem", right: "8rem" }}
          className={`${
            viewclicked ? "" : "hide"
          } z-12 fixed flex justify-center w-80`}
        >
          <ViewJob jobdata={jobdata} />
        </div>
      )}
      <p className="gray tc f3-l f3-m f5 mt4 ma0 mb0">
        Moyyn Company Dashboard
      </p>
      {!viewclicked && (
        <div className="w-80-l w-100 mt4 center flex flex-column">
          {/* job info */}
          <div className="flex flex-row-l flex-row-m flex-column">
            <div className="flex">
              <ChevronLeftTwoToneIcon
                onClick={() => history.push("/Jobs")}
                className="self-center dim pointer"
              />
              <div className="flex flex-column items-start">
                <p className="ma0 f6-l f6-m f7 pb2 tc">{`(${jobdata.careerLevel}) ${jobdata.jobTitle} - ${jobdata.jobCode}`}</p>
                <p className="ma0 pl2 f7-l f7-m f8 gray tc">{`${jobdata.city}, ${jobdata.country}`}</p>
              </div>
            </div>
            <button
              onClick={() => {
                setviewclicked(true);
                setoverlay(true);
              }}
              style={{ background: "#6EB6FF" }}
              className="ml-auto-l ml-auto-m ml3 mt3 mt0-l mt0-m c-shadow h2 pointer h7-mo fw6 f8-mo f7-m f6-l mr2 w-20-l w-20-m w4 bn link dim br2 ph3 pv2 dib white"
            >
              View Job
            </button>
          </div>
          <Tweak
            modata={moformdata}
            jobdata={jobdata}
            setjobdata={setmoformdata}
            setrerun={setrerunmomatch}
            mdef={mdef}
            mcandidate_type={mcandidate_type}
            changebtn={changebtn}
            mapplied={mapplied}
          />
          <Filter
            setfilter={setfilter}
            mdef={mdef}
            mcandidate_type={mcandidate_type}
            changebtn={changebtn}
            setchanged={setchanged}
            setisloading={setisloading}
            setstopload={setstopload}
          />
          <div className="flex flex-row-l flex-column  mt4 w-100">
            <div className="flex flex-column justify-around items-center w-100">
              <p className="ma0 gray f6-l f7-m f8-mo">For Moyyn</p>
              <div
                onClick={() => {
                  setchangebtn(true);
                }}
                style={{ borderColor: "rgb(249, 246, 246)" }}
                className="flex self-start w-100 justify-start-l justify-center pt4 ml2-l pb1"
              >
                <Button
                  onClick={() => {
                    defaultfunc(setmdef, setmcandidate_type, false);
                  }}
                  variant="contained"
                  className={`cbtn ${
                    mdef &&
                    !mcandidate_type &&
                    !mapplied &&
                    !mwithdrawn &&
                    changebtn
                      ? "cbtn-active"
                      : ""
                  }`}
                >
                  Candidates
                </Button>
                <Button
                  onClick={() => {
                    appliedfunc(setmdef, setmcandidate_type, false);
                  }}
                  variant="contained"
                  className={`cbtn ${
                    !mdef &&
                    !mcandidate_type &&
                    mapplied &&
                    !mwithdrawn &&
                    changebtn
                      ? "cbtn-active"
                      : ""
                  }`}
                >
                  Applied
                </Button>
                <Button
                  onClick={() => {
                    shortlist(setmdef, setmcandidate_type, false);
                  }}
                  variant="contained"
                  className={`cbtn ${
                    mcandidate_type &&
                    !mdef &&
                    !mapplied &&
                    !mwithdrawn &&
                    changebtn
                      ? "cbtn-active"
                      : ""
                  }`}
                >
                  Shortlisted
                </Button>
                <Button
                  onClick={() => {
                    reject(setmdef, setmcandidate_type, false);
                  }}
                  variant="contained"
                  className={`cbtn ${
                    !mcandidate_type &&
                    !mdef &&
                    !mapplied &&
                    !mwithdrawn &&
                    changebtn
                      ? "cbtn-active"
                      : ""
                  }`}
                >
                  Rejected
                </Button>
                <Button
                  onClick={() => {
                    withfunc(setmdef, setmcandidate_type, false);
                  }}
                  style={{ display: "none" }}
                  variant="contained"
                  className={`cbtn ${
                    !mdef &&
                    !mcandidate_type &&
                    !mapplied &&
                    mwithdrawn &&
                    changebtn
                      ? "cbtn-active"
                      : ""
                  }`}
                >
                  Withdrawn
                </Button>
                <Button
                  onClick={() => {
                    selectfun(setmdef, setmcandidate_type, false);
                  }}
                  variant="contained"
                  className={`cbtn ${
                    mcandidate_type &&
                    mdef &&
                    !mapplied &&
                    !mwithdrawn &&
                    changebtn
                      ? "cbtn-active"
                      : ""
                  }`}
                >
                  Recommended 
                </Button>
              </div>
            </div>
            <div className="flex flex-column justify-around mt3 mt0-l items-center w-100">
              <p className="ma0 gray f6-l f7-m f8-mo">For Client</p>
              <div
                onClick={() => {
                  setchangebtn(false);
                }}
                style={{ borderColor: "rgb(249, 246, 246)" }}
                className="flex self-start w-100 justify-end-l justify-center pt4 pb1"
              >
                <Button
                  onClick={() => {
                    defaultfunc(setdef, setcandidate_type, true);
                  }}
                  variant="contained"
                  className={`cbtn ${
                    def && !candidate_type && !changebtn ? "cbtn-active" : ""
                  }`}
                >
                  Candidates
                </Button>
                <Button
                  onClick={() => {
                    shortlist(setdef, setcandidate_type, true);
                  }}
                  variant="contained"
                  className={`cbtn ${
                    candidate_type && !def && !changebtn ? "cbtn-active" : ""
                  }`}
                >
                  Shortlisted
                </Button>
                <Button
                  onClick={() => {
                    reject(setdef, setcandidate_type, true);
                  }}
                  variant="contained"
                  className={`cbtn ${
                    !candidate_type && !def && !changebtn ? "cbtn-active" : ""
                  }`}
                >
                  Rejected
                </Button>
                <Button
                  onClick={() => {
                    selectfun(setdef, setcandidate_type, true);
                  }}
                  variant="contained"
                  className={`cbtn ${
                    candidate_type && def && !changebtn ? "cbtn-active" : ""
                  }`}
                >
                  Selected
                </Button>
              </div>
            </div>
          </div>
          <div className="mv3">
            <p className="ma0 gray mr2 f6-l f7-m f8-mo tr">
              {"All Candidates"}({count})
            </p>
          </div>

          {(isloading && stopload) || (nrml.length <= 0 && hasmore) ? (
            <div className="flex items-center justify-center ma3 br2">
              <Loading text="Loading Candidates" />
            </div>
          ) : (
            <div className="w-100 flex center flex-column">
              {candidates.length <= 0 || candidates === undefined ? (
                <div className="mt4 flex justify-center items-center">
                  <p className="ma0 f3-l f4-m f6 gray tc">
                    No, candidates matched :(
                  </p>
                </div>
              ) : mdef && mcandidate_type && changebtn ? (
                selected.length <= 0 ? (
                  <div className="flex mt4 justify-center items-center">
                    <p className="ma0 f3-l f4-m f6 gray tc">
                      No candidates Reccomended!
                    </p>
                  </div>
                ) : (
                  selected.map((data, id) => {
                    let appliedtag = false;
                    let rectag = false;
                    let withtag = false;
                    withdrawn.map((val) => {
                      if (val === data._id) {
                        withtag = true;
                      }
                      return withtag;
                    });
                    applied.map((val) => {
                      if (val === data._id) {
                        appliedtag = true;
                      }
                      return appliedtag;
                    });
                    selected.map((val) => {
                      if (val === data._id) {
                        rectag = true;
                      }
                      return rectag;
                    });
                    return (
                      <Selected
                        withtag={withtag}
                        rectag={rectag}
                        appliedtag={appliedtag}
                        setupdate={setupdate}
                        candidate={data}
                        key={id}
                        jobid={jid}
                      />
                    );
                  })
                )
              ) : mdef && changebtn ? (
                nrml.length <= 0 ? (
                  <div className="flex mt4 justify-center items-center">
                    <p className="ma0 f3-l f4-m f6 gray tc">
                      {`Sorry, no candidate's were found!`}
                    </p>
                  </div>
                ) : (
                  nrml.map((data, id) => {
                    let appliedtag = false;
                    let rectag = false;
                    let withtag = false;
                    withdrawn.map((val) => {
                      if (val === data._id) {
                        withtag = true;
                      }
                      return withtag;
                    });
                    applied.map((val) => {
                      if (val === data._id) {
                        appliedtag = true;
                      }
                      return appliedtag;
                    });
                    selected.map((val) => {
                      if (val === data._id) {
                        rectag = true;
                      }
                      return rectag;
                    });
                    if (nrml.length === id + 1) {
                      return (
                        <div key={id} ref={lastelementref}>
                          <Candidate
                            withtag={withtag}
                            rectag={rectag}
                            maildata={maildata}
                            appliedtag={appliedtag}
                            update={update}
                            setupdate={setupdate}
                            jobid={jid}
                            candidate={data}
                            mo_backend_url={k_url}
                            key={id}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <Candidate
                          withtag={withtag}
                          rectag={rectag}
                          appliedtag={appliedtag}
                          maildata={maildata}
                          update={update}
                          setupdate={setupdate}
                          jobid={jid}
                          candidate={data}
                          mo_backend_url={k_url}
                          key={id}
                        />
                      );
                    }
                  })
                )
              ) : mcandidate_type && changebtn ? (
                shortlisted.length <= 0 ? (
                  <div className="flex mt4 justify-center items-center">
                    <p className="ma0 f3-l f4-m f6 gray tc">
                      {`No, candidate's were shortlisted yet!`}
                    </p>
                  </div>
                ) : (
                  shortlisted.map((data, id) => {
                    let appliedtag = false;
                    let rectag = false;
                    let withtag = false;
                    withdrawn.map((val) => {
                      if (val === data._id) {
                        withtag = true;
                      }
                      return withtag;
                    });
                    applied.map((val) => {
                      if (val === data._id) {
                        appliedtag = true;
                      }
                      return appliedtag;
                    });
                    selected.map((val) => {
                      if (val === data._id) {
                        rectag = true;
                      }
                      return rectag;
                    });
                    return (
                      <Shortlist
                        withtag={withtag}
                        rectag={rectag}
                        appliedtag={appliedtag}
                        maildata={maildata}
                        jobid={jid}
                        setupdate={setupdate}
                        candidate={data}
                        key={id}
                      />
                    );
                  })
                )
              ) : // : mwithdrawn && changebtn ?
              //     withdrawn.length <= 0 ? <div className='flex mt4 justify-center items-center'><p className='ma0 f3-l f4-m f6 gray tc'>No, candidate's Withdrawn!</p></div> : withdrawn.map((data, id) => <Applied maildata={maildata} jobid={jid} setupdate={setupdate} candidate={data} key={id} />)
              mapplied && changebtn ? (
                applied.length <= 0 ? (
                  <div className="flex mt4 justify-center items-center">
                    <p className="ma0 f3-l f4-m f6 gray tc">
                      {`No, candidate's Applied!`}
                    </p>
                  </div>
                ) : (
                  applied.map((data, id) => {
                    let appliedtag = false;
                    let rectag = false;
                    let withtag = false;
                    withdrawn.map((val) => {
                      if (val === data._id) {
                        withtag = true;
                      }
                      return withtag;
                    });
                    applied.map((val) => {
                      if (val === data._id) {
                        appliedtag = true;
                      }
                      return appliedtag;
                    });
                    selected.map((val) => {
                      if (val === data._id) {
                        rectag = true;
                      }
                      return rectag;
                    });
                    return (
                      <Applied
                        withtag={withtag}
                        rectag={rectag}
                        appliedtag={appliedtag}
                        maildata={maildata}
                        jobid={jid}
                        setupdate={setupdate}
                        candidate={data}
                        key={id}
                      />
                    );
                  })
                )
              ) : !mwithdrawn &&
                !mapplied &&
                !mdef &&
                !mcandidate_type &&
                changebtn ? (
                rejected.length <= 0 ? (
                  <div className="flex mt4 justify-center items-center">
                    <p className="ma0 f3-l f4-m f6 gray tc">
                      {`No, candidate's were rejected yet!`}
                    </p>
                  </div>
                ) : (
                  rejected.map((data, id) => {
                    let appliedtag = false;
                    let rectag = false;
                    let withtag = false;
                    withdrawn.map((val) => {
                      if (val === data._id) {
                        withtag = true;
                      }
                      return withtag;
                    });
                    applied.map((val) => {
                      if (val === data._id) {
                        appliedtag = true;
                      }
                      return appliedtag;
                    });
                    selected.map((val) => {
                      if (val === data._id) {
                        rectag = true;
                      }
                      return rectag;
                    });
                    return (
                      <Rejected
                        withtag={withtag}
                        rectag={rectag}
                        appliedtag={appliedtag}
                        candidate={data}
                        jobid={jid}
                        setupdate={setupdate}
                        key={id}
                      />
                    );
                  })
                )
              ) : def && candidate_type && !changebtn ? (
                selected.length <= 0 ? (
                  <div className="flex mt4 justify-center items-center">
                    <p className="ma0 f3-l f4-m f6 gray tc">
                      No candidates selected!!
                    </p>
                  </div>
                ) : (
                  selected.map((data, id) => (
                    <MSelected candidate={data} jobid={jid} key={id} />
                  ))
                )
              ) : def && !changebtn ? (
                nrml.length <= 0 ? (
                  <div className="flex mt4 justify-center items-center">
                    <p className="ma0 f3-l f4-m f6 gray tc">
                      {`Sorry, no candidate's were found!`}
                    </p>
                  </div>
                ) : (
                  nrml.map((data, id) => {
                    if (nrml.length === id + 1) {
                      return (
                        <div key={id} ref={lastelementref}>
                          <MCandidate
                            shortlist={shortlist}
                            reject={reject}
                            update={update}
                            setupdate={setupdate}
                            jobid={jid}
                            candidate={data}
                            mo_backend_url={k_url}
                            key={id}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <MCandidate
                          shortlist={shortlist}
                          reject={reject}
                          update={update}
                          setupdate={setupdate}
                          jobid={jid}
                          candidate={data}
                          mo_backend_url={k_url}
                          key={id}
                        />
                      );
                    }
                  })
                )
              ) : candidate_type && !changebtn ? (
                shortlisted.length <= 0 ? (
                  <div className="flex mt4 justify-center items-center">
                    <p className="ma0 f3-l f4-m f6 gray tc">
                      {`No, candidate's were shortlisted yet!`}
                    </p>
                  </div>
                ) : (
                  shortlisted.map((data, id) => (
                    <MShortlist
                      maildata={maildata}
                      jobid={jid}
                      candidate={data}
                      key={id}
                    />
                  ))
                )
              ) : rejected.length <= 0 ? (
                <div className="flex mt4 justify-center items-center">
                  <p className="ma0 f3-l f4-m f6 gray tc">
                    {`No, candidate's were rejected yet!`}
                  </p>
                </div>
              ) : (
                rejected.map((data, id) => (
                  <MRejected candidate={data} jobid={jid} key={id} />
                ))
              )}
              {loadmore && (
                <div className="flex justify-center items-center">
                  <Loading text="" />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Candidateslist;
