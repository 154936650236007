import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import SelectMenu from "../Momatch-Tweak/Formelements/SelectMenuForm";
import WorkExperience from "../Momatch-Tweak/Formelements/WorkExperience";
import AutocompleteChips from "../Momatch-Tweak/Formelements/AutocompleteChipsForm";
import Languages from "../Momatch-Tweak/Formelements/Languages";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import {
  careerLevelOptions,
  industries,
  cities as city,
  countries as countriesArray,
} from "../../util/data/static-data";
import { filterinitialvalues } from "../../util/data/initial-values";
import { Filterformvalidation } from "../../util/data/form-validation";
import PDLFetchField from "../Momatch-Tweak/Formelements/PDLCustomField";

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        )
    : isoCode;
}

function Filter({
  setfilter,
  mdef,
  mcandidate_type,
  changebtn,
  setchanged,
  setisloading,
  setstopload,
}) {
  const screenatsamll = useMediaQuery("(max-width:600px)");
  const [constructSearchFilter, setconstructSearchFilter] = useState(null);
  const [debouncedTerm, setdebouncedTerm] = useState({});
  const [country, setcountry] = useState("");
  const [cities, setcities] = useState([{ name: "" }]);

  const [isFilterOpen, setisFilterOpen] = useState(false);

  useEffect(() => {
    if (!country) return;
    fetch("https://countriesnow.space/api/v0.1/countries/cities", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        country: country,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setcities(data.data);
        } else setcities(city);
      })
      .catch(() => setcities(city));
  }, [country]);

  useEffect(() => {
    const timer = setTimeout(
      () => setconstructSearchFilter(debouncedTerm),
      1000,
    );
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  useEffect(() => {
    if (!constructSearchFilter) return;
    const { value, type } = constructSearchFilter;
    switch (type) {
      case "email":
        if (value.length <= 0) setfilter(null);
        else setfilter({ email: value });
        break;
      case "name":
        if (value.length <= 0) setfilter(null);
        // eslint-disable-next-line no-case-declarations
        const fullname = value.split(" ");
        setfilter({ firstName: fullname[0] });
        break;
      default:
        setfilter(null);
    }
  }, [constructSearchFilter]);

  return (
    <>
      {/* Search Bar */}
      <div
        className={`${
          mdef && !mcandidate_type && changebtn ? "" : "dn hide"
        } flex items-center mt4`}
      >
        <h1 className={`gray f4-l f4-m f6 mr2 ma0 mb0`}>
          Advance Candidates Filter
        </h1>
        <div
          onClick={() => setisFilterOpen((prev) => !prev)}
          className={`${
            isFilterOpen ? "rotate-180" : ""
          } pointer flex items-center`}
        >
          <ArrowDropDownCircleOutlinedIcon style={{ color: "#777777" }} />
        </div>
      </div>
      <div
        className={`${
          mdef && !mcandidate_type && changebtn && isFilterOpen ? "" : "dn hide"
        } bg-white pa3 mt3`}
      >
        <Formik
          initialValues={filterinitialvalues}
          validationSchema={Filterformvalidation}
          onSubmit={(values) => {
            const Values = Object.keys(values).filter(
              (v) => values[v].length > 0,
            );
            const FinalValues = {};
            Values.map((val) => {
              FinalValues[val] = values[val];
              return val;
            });
            setfilter(FinalValues);
          }}
        >
          {(props) => {
            const {
              values, // eslint-disable-line
              setFieldValue,
              handleSubmit,
              resetForm,
            } = props;
            setcountry(values.country);
            return (
              <div className="mt2">
                <div className="">
                  <div className="mt3 flex flex-row-l flex-row-m flex-column">
                    <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m">
                      <SelectMenu
                        name="careerLevel"
                        label="Career level"
                        options={careerLevelOptions}
                      />
                    </div>
                    <div className="w2"></div>
                    <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m lang">
                      <Languages name="languages" filter={true} />
                    </div>
                  </div>
                  <div className="mt3 flex flex-row-l flex-row-m flex-column lang">
                    <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m">
                      <AutocompleteChips
                        name="industries"
                        label="Industries"
                        options={industries}
                      />
                    </div>
                    <div className="w2"></div>
                    <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m">
                      <PDLFetchField
                        name="skills"
                        placeholder="Primary Skills"
                        filter={true}
                        pdlName="skill"
                      />
                    </div>
                    <div className="w2"></div>
                    <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m">
                      <PDLFetchField
                        mskill={false}
                        name="skills_secondary"
                        placeholder="Secondary Skills"
                        pdlName="skill"
                      />
                    </div>
                  </div>
                  <div className="mt3 flex flex-row-l flex-row-m flex-column lang">
                    <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m">
                      <Autocomplete
                        options={countriesArray}
                        name="country"
                        placeholder="Country"
                        value={values.country}
                        inputValue={values.country}
                        onInputChange={(event, newInputValue) => {
                          setFieldValue("country", newInputValue);
                        }}
                        getOptionLabel={(option) =>
                          option.label ? option.label : option
                        }
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{countryToFlag(option.code)}</span>
                            {option.label} ({option.code})
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="country"
                            label="Choose a country"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                    <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m ml3">
                      <Autocomplete
                        options={cities}
                        name="city"
                        placeholder="City"
                        disabled={!values.country}
                        inputValue={values.city}
                        onInputChange={(event, newInputValue) => {
                          setFieldValue("city", newInputValue);
                        }}
                        getOptionLabel={(option) =>
                          option.city ? option.city : option
                        }
                        renderOption={(option) => (
                          <React.Fragment>{option}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="city"
                            label="Choose a city"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="mt3">
                    <WorkExperience />
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    style={{ background: "#6EB6FF" }}
                    className="mt3 pointer fw6 f7 f6-l w-20-l w-30-m w-40 bn link dim br1  ph3 pv2 ph4-m pv3-m ph4-l pv3-l mb2 dib white"
                  >
                    Filter <span className="hide-mo">Candidates</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setfilter(null);
                      setchanged(true);
                      setisloading(true);
                      setstopload(true);
                      resetForm(true);
                    }}
                    style={{ background: "#6EB6FF" }}
                    className="mt3 ml4 pointer fw6 f7 f6-l w-20-l w-30-m w-40 bn link dim br1  ph3 pv2 ph4-m pv3-m ph4-l pv3-l mb2 dib white"
                  >
                    Clear <span className="hide-mo">Filter</span>
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
      {/* email and name search */}
      <div
        className={`${
          mdef && !mcandidate_type && changebtn ? "" : "dn hide"
        }flex justify-start items-center mt4`}
      >
        <div className={`flex items-center relative`}>
          <input
            onChange={(e) =>
              setdebouncedTerm({ type: "email", value: e.target.value })
            }
            type="text"
            placeholder="Search with Email"
            className={`${
              screenatsamll ? "h2 w-60 f7" : ""
            } c-shadow pa2 pl5 h-30 bn br4 f6`}
          />
          <div className="gray absolute left-1 flex items-center">
            <SearchIcon />
          </div>
        </div>
        <div className={`flex items-center relative ml4`}>
          <input
            onChange={(e) =>
              setdebouncedTerm({ type: "name", value: e.target.value })
            }
            type="text"
            placeholder="Search by Name"
            className={`${
              screenatsamll ? "h2 w-60 f7" : ""
            } c-shadow pa2 pl5 h-30 bn br4 f6`}
          />
          <div className="gray absolute left-1 flex items-center">
            <SearchIcon />
          </div>
        </div>
      </div>
    </>
  );
}

export default Filter;
