import React, { useState, useEffect } from "react";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import "../../styles/candidate.scss";
import Commentbox from "./Commentbox";
import ResumePreview from "./ResumePreview";
import { useMediaQuery } from "react-responsive";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { cv_def, cv_prefix, cv_prefix_gr } from "../../util/data/dummy";
import { url } from "../../util/data/static-data";

export const MCandidatebox = React.memo(({ candidate, jobid, k }) => {
  const [isPreviewOpen, setisPreviewOpen] = useState(false);
  const ismobile = useMediaQuery({ query: `(max-width: 580px)` });
  // const {cv} = candidate;

  const [lang, setlang] = useState([]);
  const [candidateid, setcandidateid] = useState("");
  const [cv, setcv] = useState("");

  useEffect(() => {
    setcandidateid(candidate._id);
    if (lang.length <= 0) {
      candidate.languages.map((val) =>
        setlang((lang) => [...lang, val.language]),
      );
    }
  }, [candidate, lang]);

  useEffect(() => {
    let cvv = "";
    if (!candidate.cv.german && !candidate.cv.english) {
      cvv = cv_def;
    } else if (candidate.cv.english) {
      cvv = cv_prefix + candidate.cv.filename;
    } else {
      cvv = cv_prefix_gr + candidate.cv.filename;
    }
    setcv(cvv);
  }, [candidate]);

  return (
    <div
      key={k}
      style={{ borderRadius: "6px", borderLeft: "3px solid #6EB6FF" }}
      className={`dn w-100 relative ma1 dib ch bg-white pa3 mt3 br4}`}
    >
      <ResumePreview
        open={isPreviewOpen}
        close={() => setisPreviewOpen(!isPreviewOpen)}
        src={`${cv}#toolbar=0`}
      />
      <div className="flex justify-between items-start mt2-l mt0">
        <div className="flex flex-1 items-center">
          <div className="flex w-100 name_candidatebox flex-column">
            <p className="truncate ma0 f4-l f5-m f7">
              {candidate.firstName + " " + candidate.lastName}
            </p>
            <p
              style={{ color: "#6EB6FF" }}
              className="ma0 gray f7-l mt1 f7-m f8-mo"
            >
              {candidate.careerLevel}
            </p>
            <p className="truncate ma0 mt2_2 gray f8 f9-m f10-mo mt1">
              {lang.join(", ")}
            </p>
          </div>
        </div>
        <div
          className={`flex flex-1 flex-column items-start ${
            ismobile ? "hide" : ""
          }`}
        >
          <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
            Relocation willingness:{" "}
            {candidate.relocationWillingnessFlag ? "Yes" : "No"}
          </p>
          <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
            Notice Period: {candidate.noticePeriod} Months
          </p>
          <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
            Start Date:{" "}
            {candidate.earliestJoiningDate === "code422"
              ? "not available"
              : candidate.earliestJoiningDate}
          </p>
        </div>
        <div className="flex-1 mt3 flex justify-around items-center">
          <div
            onClick={() =>
              navigator.userAgent.indexOf("Chrome") !== -1 &&
              navigator.vendor.indexOf("Google Inc") !== -1
                ? window.open(
                    `${cv}`,
                    "_blank",
                    "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                  )
                : setisPreviewOpen((prev) => !prev)
            }
            className="ico mb2 pointer flex flex-column items-center self-center"
          >
            <div style={{ color: "gray" }}>
              <DescriptionOutlinedIcon />
            </div>
            <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
          </div>
          <Commentbox
            clientside={true}
            candidateid={candidateid}
            jobid={jobid}
            url={url}
          />
        </div>
      </div>
      {/* <p className={`${dim?"absolute left-1 ma0 fw6 pl1 pr1 br2 f8":"dn"}`} style={{border:`1px solid ${dim?shortclicked?"green":rejclicked?"red":"":"#6EB6FF"}`, color:dim?shortclicked?"green":rejclicked?"red":"":"#6EB6FF",top:"0.5rem"}}>{dim?shortclicked?"Shortlisted":rejclicked?"Rejected":"":"Recomended by Moyyn"}</p> */}
      <p
        className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
        style={{
          border: `1px solid ${"#6EB6FF"}`,
          color: "#6EB6FF",
          top: "0.5rem",
          left: "1rem",
        }}
      >
        {"Talent Pool"}
      </p>
      <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
        Registered on {new Date(candidate.createdAt).toLocaleDateString()}
      </p>
    </div>
  );
});

export const MShortlist = React.memo(({ candidate, jobid, maildata, k }) => {
  const [isPreviewOpen, setisPreviewOpen] = useState(false);
  const istab = useMediaQuery({ query: `(max-width: 1024px)` });
  const ismobile = useMediaQuery({ query: `(max-width: 580px)` });

  const [lang, setlang] = useState([]);

  const [candidateid, setcandidateid] = useState(candidate._id);

  const [candidatesdata, setcandidatesdata] = useState({});
  const [cv, setcv] = useState("");

  const [userdeleted, setuserdeleted] = useState(false);

  useEffect(() => {
    if (candidateid !== "") {
      fetch(url + "/momatch/" + candidateid)
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            setcandidatesdata(res.data[0]);
            if (lang.length <= 0) {
              res.data[0].languages.map((val) =>
                setlang((lang) => [...lang, val.language]),
              );
            }
            setcandidateid(res.data[0]._id);
          } else {
            if (!res.success && res.msg === "not found") {
              setuserdeleted(true);
            }
          }
        });
    }
  }, [candidateid, lang]);

  useEffect(() => {
    if (candidatesdata.cv === undefined) return;
    setcv(
      `${candidatesdata.cv.english ? cv_prefix : cv_prefix_gr}${
        candidatesdata.cv.filename
      }`,
    );
  }, [candidatesdata]);

  const mail_body = `Dear ${
    candidatesdata.firstName + " " + candidatesdata.lastName
  },%0d%0a %0d%0aGreetings from ${maildata.company}.%0d%0aI am ${
    maildata.username
  } from ${
    maildata.company
  } and your profile from Moyyn platform has been shortlisted by our company for the position of ${
    maildata.jobtittle
  }. We liked your application and would be interested in knowing more about you.%0d%0aJob Url: ${
    maildata.joblink
  }%0d%0aPlease let me know whether you are interested in this position so that we can schedule an interview.%0d%0a %0d%0aBest regards,%0d%0a${
    maildata.username
  }%0d%0aSourced from Moyyn`;
  const mail_sub = `You have been shortlisted for interview by ${maildata.company} via Moyyn`;

  return (
    <>
      {userdeleted ? (
        <div
          key={k}
          style={{ borderRadius: "6px", borderLeft: "3px solid red" }}
          className={`w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
        >
          <div>
            <p>Candidate has deleted their profile</p>
          </div>
          <p
            className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
            style={{
              border: `1px solid ${"red"}`,
              color: "red",
              top: "0.5rem",
              left: `${"1rem"}`,
            }}
          >
            {"Deleted"}
          </p>
        </div>
      ) : (
        <div
          key={k}
          style={{ borderRadius: "6px", borderLeft: "3px solid #6EB6FF" }}
          className={`dn w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
        >
          <ResumePreview
            open={isPreviewOpen}
            close={() => setisPreviewOpen(!isPreviewOpen)}
            src={`${cv}#toolbar=0`}
          />
          <div className="flex justify-between items-start mt2-l mt0">
            <div className="flex flex-1 name_candidatebox items-center">
              <div className="flex flex-column">
                <p className="truncate ma0 f4-l f5-m f7">
                  {candidatesdata.firstName + " " + candidatesdata.lastName}
                </p>
                <p
                  style={{ color: "#6EB6FF" }}
                  className="ma0 gray f7-l mt1 f7-m f8-mo"
                >
                  {candidatesdata.careerLevel}
                </p>
                <p className="truncate ma0 mt2_2 gray f8 f9-m f10-mo mt1">
                  {lang.join(", ")}
                </p>
              </div>
            </div>
            <div
              className={`flex flex-1 flex-column items-start ${
                ismobile ? "hide" : ""
              }`}
            >
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Relocation willingness:{" "}
                {candidate.relocationWillingnessFlag ? "Yes" : "No"}
              </p>
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Notice Period: {candidatesdata.noticePeriod} Months
              </p>
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Start Date:{" "}
                {candidatesdata.earliestJoiningDate === "code422"
                  ? "not available"
                  : candidatesdata.earliestJoiningDate}
              </p>
            </div>
            <div className="flex-1 flex justify-around items-center mt3">
              <div
                onClick={() =>
                  navigator.userAgent.indexOf("Chrome") !== -1 &&
                  navigator.vendor.indexOf("Google Inc") !== -1
                    ? window.open(
                        `${cv}`,
                        "_blank",
                        "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                      )
                    : setisPreviewOpen((prev) => !prev)
                }
                className="ico mb2 pointer flex flex-column items-center self-center"
              >
                <div style={{ color: "gray" }}>
                  <DescriptionOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
              </div>
              <Commentbox
                clientside={true}
                candidateid={candidateid}
                jobid={jobid}
                url={url}
              />
              <div
                onClick={(e) => {
                  window.location.href = `mailto:${candidatesdata.email}?subject=${mail_sub}&body=${mail_body}`;
                  e.preventDefault();
                }}
                className={`ico mb2 pointer flex flex-column items-center self-center ${
                  istab ? "hide" : ""
                }`}
              >
                <div style={{ color: "gray" }}>
                  <MailOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Mail</p>
              </div>
            </div>
          </div>
          <p
            className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
            style={{
              border: `1px solid ${"#6EB6FF"}`,
              color: "#6EB6FF",
              top: "0.5rem",
              left: `${"1rem"}`,
            }}
          >
            {"Talent Pool"}
          </p>
          <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
            Registered on{" "}
            {new Date(candidatesdata.createdAt).toLocaleDateString()}
          </p>
        </div>
      )}
    </>
  );
});

export const MRejected = React.memo(({ candidate, jobid, k }) => {
  const [isPreviewOpen, setisPreviewOpen] = useState(false);
  const ismobile = useMediaQuery({ query: `(max-width: 580px)` });

  const [lang, setlang] = useState([]);

  const [candidateid, setcandidateid] = useState(candidate._id);

  const [candidatesdata, setcandidatesdata] = useState({});
  const [cv, setcv] = useState("");

  const [userdeleted, setuserdeleted] = useState(false);

  useEffect(() => {
    if (candidateid !== "") {
      fetch(url + "/momatch/" + candidateid)
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            setcandidatesdata(res.data[0]);
            if (lang.length <= 0) {
              res.data[0].languages.map((val) =>
                setlang((lang) => [...lang, val.language]),
              );
            }
            setcandidateid(res.data[0]._id);
          } else {
            if (!res.success && res.msg === "not found") {
              setuserdeleted(true);
            }
          }
        });
    }
  }, [candidateid, lang]);

  useEffect(() => {
    if (candidatesdata.cv === undefined) return;
    setcv(
      `${candidatesdata.cv.english ? cv_prefix : cv_prefix_gr}${
        candidatesdata.cv.filename
      }`,
    );
  }, [candidatesdata]);

  return (
    <>
      {userdeleted ? (
        <div
          key={k}
          style={{ borderRadius: "6px", borderLeft: "3px solid red" }}
          className={`w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
        >
          <div>
            <p>Candidate has deleted their profile</p>
          </div>
          <p
            className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
            style={{
              border: `1px solid ${"red"}`,
              color: "red",
              top: "0.5rem",
              left: `${"1rem"}`,
            }}
          >
            {"Deleted"}
          </p>
        </div>
      ) : (
        <div
          key={k}
          style={{ borderRadius: "6px", borderLeft: "3px solid #6EB6FF" }}
          className={`dn w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
        >
          <ResumePreview
            open={isPreviewOpen}
            close={() => setisPreviewOpen(!isPreviewOpen)}
            src={`${cv}`}
          />
          <div className="flex justify-between items-start mt2-l mt0">
            <div className="flex-1 flex items-center">
              <div className="flex name_candidatebox flex-column">
                <p className="truncate ma0 f4-l f5-m f7">
                  {candidatesdata.firstName + " " + candidatesdata.lastName}
                </p>
                <p
                  style={{ color: "#6EB6FF" }}
                  className="ma0 gray f7-l mt1 f7-m f8-mo"
                >
                  {candidatesdata.careerLevel}
                </p>
                <p className="truncate ma0 mt2_2 gray f8 f9-m f10-mo mt1">
                  {lang.join(", ")}
                </p>
              </div>
            </div>
            <div
              className={`flex-1 flex flex-column items-start ${
                ismobile ? "hide" : ""
              }`}
            >
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Relocation willingness:{" "}
                {candidate.relocationWillingnessFlag ? "Yes" : "No"}
              </p>
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Notice Period: {candidatesdata.noticePeriod} Months
              </p>
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Start Date:{" "}
                {candidatesdata.earliestJoiningDate === "code422"
                  ? "not available"
                  : candidatesdata.earliestJoiningDate}
              </p>
            </div>
            <div className="flex-1 flex justify-around items-center mt3">
              <div
                onClick={() =>
                  navigator.userAgent.indexOf("Chrome") !== -1 &&
                  navigator.vendor.indexOf("Google Inc") !== -1
                    ? window.open(
                        `${cv}`,
                        "_blank",
                        "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                      )
                    : setisPreviewOpen((prev) => !prev)
                }
                className="ico mb2 pointer flex flex-column items-center self-center"
              >
                <div style={{ color: "gray" }}>
                  <DescriptionOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
              </div>
              <Commentbox
                clientside={true}
                candidateid={candidateid}
                jobid={jobid}
                url={url}
              />
            </div>
          </div>
          <p
            className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
            style={{
              border: `1px solid ${"#6EB6FF"}`,
              color: "#6EB6FF",
              top: "0.5rem",
              left: `${"1rem"}`,
            }}
          >
            {"Talent Pool"}
          </p>
          <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
            Registered on{" "}
            {new Date(candidatesdata.createdAt).toLocaleDateString()}
          </p>
        </div>
      )}
    </>
  );
});

export const MSelected = React.memo(({ candidate, jobid, k }) => {
  const [isPreviewOpen, setisPreviewOpen] = useState(false);
  const ismobile = useMediaQuery({ query: `(max-width: 580px)` });

  const [lang, setlang] = useState([]);

  const [candidateid] = useState(candidate._id);

  const [candidatesdata, setcandidatesdata] = useState({});
  const [cv, setcv] = useState("");
  const [userdeleted, setuserdeleted] = useState(false);

  useEffect(() => {
    if (candidateid !== "") {
      fetch(url + "/momatch/" + candidateid)
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            setcandidatesdata(res.data[0]);
            if (lang.length <= 0) {
              res.data[0].languages.map((val) =>
                setlang((lang) => [...lang, val.language]),
              );
            }
          } else {
            if (!res.success && res.msg === "not found") {
              setuserdeleted(true);
            }
          }
        });
    }
  }, [candidateid, lang]);

  useEffect(() => {
    if (candidatesdata.cv === undefined) return;
    setcv(
      `${candidatesdata.cv.english ? cv_prefix : cv_prefix_gr}${
        candidatesdata.cv.filename
      }`,
    );
  }, [candidatesdata]);

  return (
    <>
      {userdeleted ? (
        <div
          key={k}
          style={{ borderRadius: "6px", borderLeft: "3px solid red" }}
          className={`w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
        >
          <div>
            <p>Candidate has deleted their profile</p>
          </div>
          <p
            className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
            style={{
              border: `1px solid ${"red"}`,
              color: "red",
              top: "0.5rem",
              left: "1rem",
            }}
          >
            {"Deleted"}
          </p>
        </div>
      ) : (
        <div
          key={k}
          style={{ borderRadius: "6px", borderLeft: "3px solid #6EB6FF" }}
          className={`w-100 relative ma1 dib ch bg-white pa3 mt3 br3`}
        >
          <ResumePreview
            open={isPreviewOpen}
            close={() => setisPreviewOpen(!isPreviewOpen)}
            src={`${cv}#toolbar=0`}
          />
          <div className="flex justify-between items-start mt2-l mt0">
            <div className="flex-1 flex items-center">
              <div className="flex name_candidatebox flex-column">
                <p className="truncate ma0 f4-l f5-m f7">
                  {candidatesdata.firstName + " " + candidatesdata.lastName}
                </p>
                <p
                  style={{ color: "#6EB6FF" }}
                  className="ma0 gray f7-l mt1 f7-m f8-mo"
                >
                  {candidatesdata.careerLevel}
                </p>
                <p className="truncate ma0 mt2_2 gray f8 f9-m f10-mo mt1">
                  {lang.join(", ")}
                </p>
              </div>
            </div>
            <div
              className={`flex-1 flex flex-column items-start ${
                ismobile ? "hide" : ""
              }`}
            >
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Relocation willingness:{" "}
                {candidate.relocationWillingnessFlag ? "Yes" : "No"}
              </p>
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Notice Period: {candidatesdata.noticePeriod} Months
              </p>
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Start Date:{" "}
                {candidatesdata.earliestJoiningDate === "code422"
                  ? "not available"
                  : candidatesdata.earliestJoiningDate}
              </p>
            </div>
            <div className="flex-1 flex justify-around items-center">
              <div
                onClick={() =>
                  navigator.userAgent.indexOf("Chrome") !== -1 &&
                  navigator.vendor.indexOf("Google Inc") !== -1
                    ? window.open(
                        `${cv}`,
                        "_blank",
                        "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                      )
                    : setisPreviewOpen((prev) => !prev)
                }
                className="ico mb2 pointer flex flex-column items-center self-center"
              >
                <div style={{ color: "gray" }}>
                  <DescriptionOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
              </div>
              <Commentbox
                clientside={true}
                candidateid={candidateid}
                jobid={jobid}
                url={url}
              />
            </div>
            <div className="flex-1 h-100 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
              <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center mt1">
                <button
                  onClick={(e) => {
                    window.location.href = `mailto:${candidatesdata.email}?subject=Job at Moyyn&body=Hello, thsnks for applying`;
                    e.preventDefault();
                  }}
                  style={{ color: "#6EB6FF" }}
                  className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-80-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                >
                  <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                    <MailOutlinedIcon />
                    <span className="ml2">Send Mail</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          {/* <p className={`${dim?"absolute left-1 ma0 fw6 pl1 pr1 br2 f8":"dn"}`} style={{border:`1px solid ${dim || shortclicked?"green":dim || rejclicked?"red":"#6EB6FF"}`, color:dim || shortclicked?"green":dim || rejclicked?"red":"#6EB6FF",top:"0.5rem"}}>{dim || shortclicked?"Shortlisted":dim || rejclicked?"Rejected":"Recomended by Moyyn"}</p> */}
          <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
            Registered on{" "}
            {new Date(candidatesdata.createdAt).toLocaleDateString()}
          </p>
          <p
            className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
            style={{
              border: `1px solid ${"#6EB6FF"}`,
              color: "#6EB6FF",
              top: "0.5rem",
              left: `${"1rem"}`,
            }}
          >
            {"Talent Pool"}
          </p>
        </div>
      )}
    </>
  );
});
