import React, { useState, useEffect } from "react";
import "tachyons";

export default function ViewJob({ userdata }) {
  const [lang, setlang] = useState([]);

  useEffect(() => {
    userdata.languages.map((val) =>
      setlang((lang) => [...lang, val.language + "( " + val.level + " )"]),
    );
  }, [userdata]);

  return (
    <div
      style={{ height: "600px", overflow: "auto" }}
      className={`bg-white br4 remove-scrollbar pa4 w-80 `}
    >
      <h2>Candidate Details</h2>
      <br />
      <h4>Name</h4>
      <p className="gray f6 lh-copy">{`${userdata.firstName} ${userdata.lastName}`}</p>
      <h4>Email</h4>
      <p className="gray f6 lh-copy">
        <a
          href={`mailto:${userdata.email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {userdata.email}
        </a>
      </p>
      <h4>Phone</h4>
      <p className="gray f6 lh-copy">
        <a
          href={`tel:${userdata.contactNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {userdata.contactNumber}
        </a>
      </p>
      <h4>Location</h4>
      <p className="gray f6 lh-copy">{`${userdata.city}, ${userdata.country}, ${userdata.postcode}`}</p>
      <h4>City&apos;s of Preference</h4>
      <p className="gray f6 lh-copy">{userdata.cityPreferences.join(", ")}</p>
      <h4>Country&apos;s of Preference</h4>
      <p className="gray f6 lh-copy">
        {userdata.countryPreferences.join(", ")}
      </p>
      <h4>Desired Positions</h4>
      <p className="gray f6 lh-copy">{userdata.desiredPositions.join(", ")}</p>
      <h4>Industries</h4>
      <p className="gray f6 lh-copy">{userdata.industries.join(", ")}</p>
      <h4>Career Level</h4>
      <p className="gray f6 lh-copy">{userdata.careerLevel}</p>
      <h4>Work Experience</h4>
      <div>
        {userdata.workExperience.map((val, idx) => {
          return (
            <div className="" key={idx}>
              <p className="gray f6 lh-copy">Category: {val.Category}</p>
              <p className="gray f6 lh-copy">Role: {val.Role}</p>
              <p className="gray f6 lh-copy">Experience: {val.Experience}</p>
            </div>
          );
        })}
      </div>
      <h4>Languages</h4>
      <p className="gray f6 lh-copy">{lang.join(", ")}</p>
      <h4>Primary Skills</h4>
      <p className="gray f6 lh-copy">{userdata.skills.join(", ")}</p>
      {/* <h4>Secondary Skills</h4>
      <p className="gray f6 lh-copy">
        {userdata.skills_secondary === undefined && userdata.skills_secondary.length <= 0
          ? "No Skills"
          : userdata.skills_secondary.join(", ")}
      </p> */}
      <h4>Start Date</h4>
      <p className="gray f6 lh-copy">{userdata.earliestJoiningDate}</p>
      <h4>Visa Type</h4>
      <p className="gray f6 lh-copy">{userdata.visaType}</p>
      <h4>Currently Employed?</h4>
      <p className="gray f6 lh-copy">
        {userdata.currentlyEmployedFlag ? "Yes" : "No"}
      </p>
      <h4>Driving Permit?</h4>
      <p className="gray f6 lh-copy">
        {userdata.drivingPermitFlag ? "Yes" : "No"}
      </p>
      <h4>Actively Seeking Jobs?</h4>
      <p className="gray f6 lh-copy">
        {userdata.activeJobSeeking ? "Yes" : "No"}
      </p>
      <h4>Willing to Relocate?</h4>
      <p className="gray f6 lh-copy">
        {userdata.relocationWillingnessFlag ? "Yes" : "No"}
      </p>
    </div>
  );
}
