import React, { useEffect, useState } from "react";
import UnapprovedCompany from "../Company/Companylist";
import ApprovedCompany from "../Company/Approvedlist";
import "../../styles/Dashboard.scss";
import { url } from "../../util/data/static-data";
import Loading from "../Loading/Loading";
import { useHistory } from "react-router-dom";

function Dashboard({ setjobdata, id }) {
  const history = useHistory();

  const [pps, setpps] = useState(false);
  const [free, setfree] = useState(true);
  const [reload, setreload] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [error, seterror] = useState(false);

  const [companylist, setcompanylist] = useState({
    approved: [],
    unapproved: [],
  });

  useEffect(() => {
    if (id === "") return;
    setisloading(true);
    fetch(`${url}/admin/fetch`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        admin_id: id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setcompanylist({
            approved: data.approved,
            unapproved: data.unapproved,
          });
          setisloading(false);
        } else {
          setisloading(false);
          seterror(true);
        }
      })
      .catch(() => {
        setisloading(false);
        seterror(true);
      });
  }, [id, reload]);

  if (error) {
    return (
      <React.Fragment>
        <div className="flex flex-column items-center justify-center bg-white ma3 br2 vh-75">
          <p className="f3 gray">Something Went Wrong!</p>
          <button
            className="mt4 fw6 f6 bn dim br1 ph3 pointer pv2 dib white"
            style={{ background: "#6EB6FF" }}
            onClick={() => {
              history.push("/dashboard");
              seterror(false);
            }}
          >
            Go Back
          </button>
        </div>
      </React.Fragment>
    );
  }

  if (isloading) {
    return (
      <div className="flex items-center justify-center bg-white ma3 br2 vh-75">
        <Loading text="Loading Dashboard..." />
      </div>
    );
  }

  return (
    <div className="">
      <div className="ma4-l ma4-m ma0 mt4">
        <p className="gray tl f5-l f5-m f6 center w-80-l w-80-m w-90 ma0">
          Pending Approvals
        </p>
      </div>
      <UnapprovedCompany
        setreload={setreload}
        seterror={seterror}
        companypendinglist={companylist.unapproved}
      />
      <div className="ma4-l ma4-m ma0 mt4">
        <p className="gray tl f5-l f5-m f6 ma0 center w-80-l w-80-m w-90">
          Approved Clients
        </p>
      </div>
      <div className="ma4-l ma4-m ma0 mt4">
        <div
          style={{ borderColor: "rgb(249, 246, 246)" }}
          className="flex w-80-l w-80-m w-90 center"
        >
          <div onClick={() => {
            setpps(false);
            setfree(true);
          }} className="flex-1">
            <button
              style={{ background: `${free ? '#6EB6FF' : '#fff'}`, color: `${free ? '' : '#000'}` }}
              className="c-shadow pointer f8-mo f7-m f6-l w-100 h2 bn link dim br1 ph3 pv2 dib white"
            >
              Free
            </button>
          </div>
          <div className="w1"></div>
          <div
            onClick={() => {
              setpps(true);
              setfree(false);
            }}
            className="flex-1"
          >
            <button
              style={{ background: `${pps ? '#6EB6FF' : '#fff'}`, color: `${pps ? '' : '#000'}` }}
              className="c-shadow pointer f8-mo f7-m f6-l w-100 h2 bn link dim br1 ph3 pv2 dib white"
            >
              Paid Clients
            </button>
          </div>
        </div>
      </div>
      <ApprovedCompany
        approvedclientlist={companylist.approved}
        setjobdata={setjobdata}
        seterror={seterror}
        pps={pps}
        free={free}
        setreload={setreload}
      />
    </div>
  );
}

export default Dashboard;
