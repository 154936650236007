import React from "react";
import Company from "../Company/Company";

export default function Companylist({
  companypendinglist,
  setreload,
  seterror,
}) {
  return (
    <div>
      {companypendinglist !== undefined && companypendinglist.length > 0 ? (
        companypendinglist.map((companypending, id) => {
          return (
            <Company
              setreload={setreload}
              seterror={seterror}
              companydata={companypending}
              key={id}
            />
          );
        })
      ) : (
        <div className="flex mt4 justify-center items-center">
          <p className="ma0 f3-l f4-m f6 gray tc">{`No, company's were found!`}</p>
        </div>
      )}
    </div>
  );
}
