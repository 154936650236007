import React, { useState } from "react";
import { FieldArray, ErrorMessage } from "formik";
import makeStyles from '@mui/styles/makeStyles';
import { languagesObject } from "../../../util/data/static-data";
import {
  Grid,
  TextField,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  FormHelperText,
  Typography,
  Autocomplete
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const languagesArray = Object.values(languagesObject).map(
  (option) => option.name,
);

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: "red",
  },
}));

const levels = ["A1", "A2", "B1", "B2", "C1", "C2", "Native"];

const Languages = ({ name = "Languages", filter = false }) => {
  const custom = useStyles();

  const [valueLang, setValueLang] = useState("");
  const [inputValueLang, setInputValueLang] = useState("");

  return (
    <FieldArray name={name}>
      {({ form, push, remove }) => {
        const handleLevelSelect = (level) => {
          if (filter) {
            if (
              !form.values.languages.includes((item) => (item.name = valueLang))
            ) {
              push({
                language: valueLang,
                level,
              });
              setValueLang("");
            }
          } else {
            if (
              !form.values.Languages.includes((item) => (item.name = valueLang))
            ) {
              push({
                language: valueLang,
                level,
              });
              setValueLang("");
            }
          }
        };
        return (
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Autocomplete
                options={languagesArray}
                value={valueLang}
                onChange={(event, newValue) => {
                  setValueLang(newValue);
                }}
                inputValue={inputValueLang}
                onInputChange={(event, newInputValue) => {
                  setInputValueLang(newInputValue);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Languages"
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name={name}
                onBlur={form.handleBlur}
                fullWidth
                select
                className="lvl"
                variant="outlined"
                label="Level"
                disabled={!valueLang}
                value=""
              >
                {levels.map((level) => (
                  <MenuItem
                    key={level}
                    onClick={() => handleLevelSelect(level)}
                  >
                    {level}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <ErrorMessage name={name}>
              {(error) => {
                return (
                  <FormHelperText className={custom.errorText}>
                    {error}
                  </FormHelperText>
                );
              }}
            </ErrorMessage>
            <Grid item xs={12}>
              <List>
                {filter
                  ? form.values.languages.map((item, i) => {
                      return (
                        <ListItem key={i} button divider>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="subtitle2"
                                  // className={classes.inline}
                                  color="textPrimary"
                                >
                                  {item.language}
                                  <span style={{ fontSize: "0.8rem" }}>
                                    {` — ${item.level}`}
                                  </span>
                                </Typography>
                              </React.Fragment>
                            }
                          />
                          <HighlightOffIcon onClick={() => remove(i)} />
                        </ListItem>
                      );
                    })
                  : form.values.Languages.map((item, i) => {
                      return (
                        <ListItem key={i} button divider>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="subtitle2"
                                  // className={classes.inline}
                                  color="textPrimary"
                                >
                                  {item.language}
                                  <span style={{ fontSize: "0.8rem" }}>
                                    {` — ${item.level}`}
                                  </span>
                                </Typography>
                              </React.Fragment>
                            }
                          />
                          <HighlightOffIcon onClick={() => remove(i)} />
                        </ListItem>
                      );
                    })}
              </List>
            </Grid>
          </Grid>
        );
      }}
    </FieldArray>
  );
};

export default Languages;
