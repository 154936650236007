import React, { useState, useEffect } from "react";
import "../styles/App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import Header from "../Components/Header/DashboardHeader";
import Login from "../Components/AdminLogin/Login";
import Dashboard from "../Components/Dashboard/Dashboard";
import "tachyons";
import Jobs from "../Components/Jobs/Joblist";
import Candidates from "../Components/Candidates/Candidateslist";
import Credits from "../Components/Company/Credits";
import Settings from "../Components/Settings/Settings";

function App() {
  const history = useHistory();

  const [loggedin, setloggedin] = useState(false);
  const [id, setid] = useState("");
  const [error, seterror] = useState(false);

  useEffect(() => {
    setloggedin(JSON.parse(localStorage.getItem("logstate")));
    setid(JSON.parse(localStorage.getItem("id")));
  }, []);

  if (error) {
    return (
      <React.Fragment>
        <div className="flex flex-column items-center justify-center bg-white ma3 br2 vh-75">
          <p className="f3 gray">Something Went Wrong!</p>
          <button
            className="mt4 fw6 f6 bn dim br1 ph3 pointer pv2 dib white"
            style={{ background: "#6EB6FF" }}
            onClick={() => {
              history.push("/dashboard");
              seterror(false);
            }}
          >
            Go Back
          </button>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            {loggedin ? <Redirect to="/dashboard" /> : ""}
            <Login setid={setid} setlog={setloggedin} />
          </Route>
          <Route exact path="/dashboard">
            {loggedin ? "" : <Redirect to="/dashboard" />}
            <div>
              <Header setlog={setloggedin} />
              <Dashboard id={id} />
            </div>
          </Route>
          <Route exact path="/jobs">
            <Header setlog={setloggedin} />
            <div className="flex ma-4 w-90-l w-90-m w-100 center ">
              <Jobs id={id} seterror={seterror} />
            </div>
          </Route>
          <Route exact path="/credits/manage">
            <Header setlog={setloggedin} />
            <div className="flex ma-4 w-90-l w-90-m w-100 center ">
              <Credits id={id} seterror={seterror} />
            </div>
          </Route>
          <Route exact path="/candidates">
            <Header setlog={setloggedin} />
            <Candidates id={id} />
          </Route>
          <Route exact path="/Settings">
            <Header setlog={setloggedin} />
            <Settings id={id} />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
