import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../styles/jobs.scss";
import { url } from "../../util/data/static-data";
import ViewJob from "./ViewJob";

export default function Job({ job }) {
  const history = useHistory();

  const [ttlcandidates, setttlcandidates] = useState(0);
  const [overlay, setoverlay] = useState(false);
  const [viewclicked, setviewclicked] = useState(false);

  useEffect(() => {
    if (job._id !== "" || job._id !== undefined || job._id !== null) {
      fetch(`${url}/momatch/${job._id}/1`)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setttlcandidates(res.count);
          }
        });
    }
  }, [job]);

  return (
    <>
      <div
        onClick={() => {
          setviewclicked(false);
          setoverlay(false);
        }}
        className={`fixed overlay top-0 bottom-0 left-0 right-0 ${
          overlay ? "active" : ""
        }`}
      ></div>
      <div
        style={{ top: "6rem" }}
        className={`${
          viewclicked ? "" : "hide"
        } z-12 fixed flex justify-center w-70`}
      >
        <ViewJob jobdata={job} />
      </div>
      <div className="card ma1 bg-white ph3 mt3 br3 flex justify-between items-center">
        <div className={`flex flex-1 w-30-l w-30-m w-50 flex-column`}>
          <p className="ma0 gray f8 f9-m f9-mo">{job.careerLevel}</p>
          <p
            style={{
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
            }}
            className="ma0 di f6-l f6-m mt1 f7_2-mo"
          >
            {job.jobTitle}
          </p>
          <p
            style={{ color: "#6EB6FF" }}
            className="ma0 gray f8 mt1 f9-m f9-mo"
          >
            {job.city}, {job.country}
          </p>
        </div>
        <div className="flex flex-1 flex-column items-center">
          <p className="ma0 dn db-m db-l mb2 gray f8 f10-m f10-mo">
            No. of Candidates{" "}
          </p>
          <p className="ma0 gray mb3 gray f7 f8-m f8-mo">{ttlcandidates}</p>
        </div>
        <div className={`h-100 flex-1 flex flex-column w-25-l w-40-m w-30`}>
          <p className="ma0 mt2 gray f8 f9-m f10-mo mb1 mb3-m mb3-l self-end">
            Created on {new Date(job.timestamp).toLocaleDateString()}
          </p>
          <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center">
            <button
              onClick={() => {
                localStorage.setItem("a_j_id", JSON.stringify(job._id));
                history.push("/Candidates");
              }}
              style={{ background: "#6EB6FF" }}
              className="c-shadow ml2-l ml2-m ml3 pointer f9-mo f8-m f7-l mr2-l mr2-m mr0 w-40-l w-50-m w-90 h2-l h2-m h7-mo bn link dim br2 ph3 pv2 dib white"
            >
              Candidates
            </button>
            <button
              onClick={() => {
                setviewclicked(true);
                setoverlay(true);
              }}
              style={{ color: "#6EB6FF" }}
              className="bg-white pointer ml2-l ml2-m ml3 c-shadow f9-mo f8-m f7-l w-40-l w-50-m w-90 h2-l h2-m h7-mo mt1 mt0-l mt0-m bn link dim br2 ph3 pv2 dib"
            >
              View Job
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
