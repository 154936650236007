import React, { useState, useEffect } from "react";
import ToggleButton from "react-toggle-button";
import { url } from "../../util/data/static-data";
import "tachyons";
import '../../styles/settings.scss';

export default function Settings({ id }) {
  const [toggle, settoggle] = useState({ client: true, candidate: true });
  const [clicked, setclicked] = useState(false);
  const [credits, setCredits] = useState(0);
  const [balance, setBalance] = useState(0);
  const [creditFlag, setCreditFlag] = useState(false);
  const [balanceFlag, setBalanceFlag] = useState(false);


  useEffect(() => {
    if (id === "") return;
    fetch(`${url}/admin/sitelive`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          if (res.data.ClientLive !== null && res.data.CandidateLive !== null) {
            settoggle({
              client: res.data[0].ClientLive,
              candidate: res.data[0].CandidateLive,
            });
          }
        }
      });
        fetch(`${url}/admin/fetch/globalcredits`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: id,
          }),
        }).then((res) => res.json()).then((res) => {
            if (res.success) setBalance(res.data[0].global_available_credits);
          }).catch(err=>console.error(err));
        setBalanceFlag(false);
  }, [id, balanceFlag]);

  useEffect(() => {
    if (id === "") return;
    if (clicked) {
      fetch(`${url}/admin/setsitelive`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: id,
          CandidateLive: toggle.candidate,
          ClientLive: toggle.client,
        }),
      }).then((res) => res.json()).then((res) => {
          if (res.success) {
            console.log("updated");
          }
        });
    }
  }, [clicked, id, toggle]);

  useEffect(() => {
    if (!credits || !creditFlag) return;
    fetch(`${url}/admin/globalcredits`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: id,
        credits: credits
      }),
    }).then((res) => res.json()).then((res) => {
        if (res.success) {
          console.log("updated gloabl credits");
        }
    }).catch(err=>console.error(err));
    setCreditFlag(false);
    setBalanceFlag(true);
  }, [credits,creditFlag])
  

  return (
    <div className="flex flex-column justify-center items-center min-vh-100">
      <div className="flex justify-around vh-25 flex-column w-40">
        <div className="balance">
          Balance: {!balance ? '0' : balance } Credits
        </div>
        <div className="flex justify-around mt-5 w-100">
          <input onChange={e => setCredits(e.target.value < 0 ? 0 : e.target.value)} value={credits} className="ba b--black-20 pa2 mb2 db w-100 mr-3 flex-2" type="number" min="0" placeholder="enter credits"/>
          <button onClick={() => setCreditFlag(true)} className="f6 link dim br1 ba ph2 pv2 mb2 dib white bg-blue w-100 flex-1">Update Credits</button>
        </div>
        <div className="flex justify-around items-center mt-5">
          <p className="gray f5-l f6-m f7 ma0 mr3">
            Turn {!toggle.client ? "off" : "on"} maintenance for
            client.moyyn.com ?
          </p>
          <div className="toggle-btn">
            <ToggleButton
              value={!toggle.client}
              onToggle={() => {
                setclicked(true);
                settoggle({
                  client: !toggle.client,
                  candidate: toggle.candidate,
                });
              }}
            />
          </div>
        </div>
        <div className="flex justify-around items-center mt-5">
          <p className="gray f5-l f6-m f7 ma0 mr3">
            Turn {!toggle.candidate ? "off" : "on"} maintenance for
            app.moyyn.com ?
          </p>
          <div className="toggle-btn">
            <ToggleButton
              value={!toggle.candidate}
              onToggle={() => {
                setclicked(true);
                settoggle({
                  client: toggle.client,
                  candidate: !toggle.candidate,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
