import React, { useState, useEffect } from "react";
import SelectMenu from "./Formelements/SelectMenuForm";
import WorkExperience from "./Formelements/WorkExperience";
import AutocompleteChips from "./Formelements/AutocompleteChipsForm";
import Languages from "./Formelements/Languages";
import { Formik } from "formik";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import {
  careerLevelOptions,
  industries,
  k_url,
  url,
} from "../../util/data/static-data";
import { postjobinitialvalues } from "../../util/data/initial-values";
import { Postjobformvalidation } from "../../util/data/form-validation";
import "../../styles/tweak.scss";
import Loading from "../Loading/Loading";
import PDLFetchField from "./Formelements/PDLCustomField";

export default function Tweak({
  modata,
  jobdata,
  setjobdata,
  setrerun,
  mdef,
  mcandidate_type,
  changebtn,
  mapplied,
}) {
  const [data, setdata] = useState({
    Industries: [],
    Skills: [],
    skills_secondary: [],
    careerLevel: "",
    Languages: [],
    workExperience: [],
  });

  const [isMomatchOpen, setisMomatchOpen] = useState(false);
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    setisloading(true);
    if (modata.Skills !== undefined) {
      const customdata = {
        Industries: modata.Industries,
        Skills: modata.Skills,
        skills_secondary: modata.skills_secondary,
        careerLevel: modata.careerLevel,
        Languages: modata.Languages,
        workExperience: modata.workExperience,
      };
      setdata(customdata);
      setisloading(false);
    }
  }, [modata]);

  const sendjobtoclient = () => {
    setisloading(true);
    fetch(`${k_url}/job/update`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(jobdata),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          fetch(`${url}/momatch/${jobdata._id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              jobid: jobdata._id,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                setisloading(false);
              }
            });
        }
      })
      .catch(() => {
        console.error("error submitting job form!");
      });
  };

  if (isloading) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-center ma3 br2">
          <Loading text="Loading Mo-Match data..." />
        </div>
      </React.Fragment>
    );
  }

  if (!(mdef && !mcandidate_type && !mapplied && changebtn)) return null;

  return (
    <>
      <div className="w-100 flex mt4">
        <h1 className="ma0 gray f4-l f4-m f6 mr2">Admin Momatch Inputs</h1>
        <div
          onClick={() => setisMomatchOpen((prev) => !prev)}
          className={`${
            isMomatchOpen ? "rotate-180" : ""
          } pointer flex items-center`}
        >
          <ArrowDropDownCircleOutlinedIcon style={{ color: "#777777" }} />
        </div>
      </div>
      {isMomatchOpen && (
        <div className="mt4 br2 bg-white c-shadow pa4">
          <Formik
            enableReinitialize={true}
            initialValues={data === {} ? postjobinitialvalues : data}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              const update = modata;
              update.Languages = values.Languages;
              update.Skills = values.Skills;
              update.skills_secondary = values.skills_secondary;
              update.careerLevel = values.careerLevel;
              update.workExperience = values.workExperience;
              update.Industries = values.Industries;
              setjobdata(update);
              setrerun(true);
            }}
            validationSchema={Postjobformvalidation}
          >
            {(props) => {
              const { isSubmitting, handleSubmit } = props;
              return (
                <div className="mt2">
                  <form method="post" onSubmit={handleSubmit}>
                    <div className="">
                      <div className="mt3 flex flex-row-l flex-row-m flex-column">
                        <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m">
                          <SelectMenu
                            name="careerLevel"
                            label="Career level"
                            options={careerLevelOptions}
                          />
                        </div>
                        <div className="w2"></div>
                        <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m lang">
                          <Languages />
                        </div>
                      </div>
                      <div className="mt3 flex flex-row-l flex-row-m flex-column lang">
                        <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m">
                          <AutocompleteChips
                            name="Industries"
                            label="Industries"
                            options={industries}
                          />
                        </div>
                        <div className="w2"></div>
                        <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m">
                          <PDLFetchField
                            name="Skills"
                            placeholder="Primary Skills"
                            pdlName="skill"
                          />
                        </div>
                        <div className="w2"></div>
                        <div className="w-80-l w-80-m w-100 mt3 mt0-l mt0-m">
                          <PDLFetchField
                            mskill={false}
                            name="skills_secondary"
                            placeholder="Secondary Skills"
                            pdlName="skill"
                          />
                        </div>
                      </div>
                      <div className="mt3">
                        <WorkExperience />
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        style={{ background: "#6EB6FF" }}
                        className="mt3 pointer fw6 f7 f6-l w-20-l w-30-m w-40 bn link dim br1  ph3 pv2 ph4-m pv3-m ph4-l pv3-l mb2 dib white"
                      >
                        <span className="hide-mo">Run</span> Momatch
                      </button>
                      <button
                        onClick={() => sendjobtoclient()}
                        style={{ background: "#6EB6FF" }}
                        className="mt3 ml3 pointer fw6 f7 f6-l w-20-l w-30-m w-40 bn link dim br1  ph3 pv2 ph4-m pv3-m ph4-l pv3-l mb2 dib white"
                      >
                        <span className="hide-mo">Send</span> To Client
                      </button>
                    </div>
                  </form>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
}
