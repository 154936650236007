import React, { useEffect, useState } from "react";
import { url } from "../../util/data/static-data";

export default function Credits() {
  const [companyId] = useState(JSON.parse(localStorage.getItem("a_c_id")));
  const [companyEmail] = useState(
    JSON.parse(localStorage.getItem("a_c_email")),
  );
  const [historyList, setHistoryList] = useState([]);
  const [grantCredits, setGrantCredits] = useState(false);
  const [approvePendingCredits, setApprovePendingCredits] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [declinePendingCredits, setDeclinePendingCredits] = useState(false);
  const [credits, setCredits] = useState(0);
  const [balance, setBalance] = useState(0);
  const [creditApprovalRequired, setCreditApprovalRequired] = useState({
    required: false,
    creditsAsked: 0,
  });

  const getPrice = (credits) => {
    const creditsEuroMap = {
      1: 2,
      50: 40,
      100: 60,
      150: 80,
      200: 100,
    };
    let cost = 0;
    Object.keys(creditsEuroMap).forEach((val) => {
      if (credits >= parseInt(val)) cost = creditsEuroMap[parseInt(val)];
    })
    return cost;
  };

  useEffect(() => {
    if ((!approvePendingCredits) && !creditApprovalRequired.required) return;
    fetch(`${url}/credit/approve`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: companyId,
        value: creditApprovalRequired.creditsAsked,
        email: companyEmail,
        timestamp: new Date(),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setDeclinePendingCredits(false);
          setApprovePendingCredits(false);
        }
      })
      .catch((err) => console.error(err));
    
  }, [approvePendingCredits])

  useEffect(() => {
    if (!grantCredits && !companyId && !companyEmail) return;
    fetch(`${url}/credit`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: companyId,
        value: parseInt(credits),
        email: companyEmail,
        timestamp: new Date(),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCredits(0);
          setGrantCredits(false);
        }
      })
      .catch((err) => console.error(err));
  }, [grantCredits]);

  useEffect(() => {
    if (!companyId) return;
    fetch(`${url}/credit/history`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: companyId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          const history = data.history.map((history) => {
            if (history.value <= 0) return null;
            return {
              ...history,
              date: new Date(history.timestamp).toLocaleDateString(),
              time: new Date(history.timestamp).toLocaleTimeString(),
            };
          });
          setHistoryList(history.filter((val) => val != null));
          setBalance(data.credits);
        }
      })
      .catch((err) => console.error(err));

    fetch(`${url}/credit/pending/approvals`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // eslint-disable-next-line array-callback-return
          data.approvals.map((val) => {
            if (val.company_id === companyId) {
              setCreditApprovalRequired({
                required: true,
                creditsAsked: val.value,
              });
            }
          });
        }
      })
      .catch((err) => console.error(err));
  }, [companyId]);

  return (
    <div className="w-100 pa4">
      <h3 className="gray ma0 mb2 f4-l f6-m">Credits Management</h3>
      <div className="gray f4-l f5-m f6 ">
          Credit Balance: {balance ?? "Not available"}
      </div>
      <div className={`flex ${creditApprovalRequired.required ? 'justify-center' : 'justify-start'} items-center`}>
        <div className={`flex justify-between items-center pa4 w-70`}>
          <div>
            <p className="tc">Grant custom credits</p>
            <div className="flex items-center justify-center">
              <input
                value={credits}
                onChange={(e) => setCredits(e.target.value)}
                type="number"
                min={0}
                placeholder="Credits"
                className="mr2 pa2 input-reset ba bg-transparent w-40 tc br2"
              />
              <button
                style={{ background: "#6EB6FF" }}
                className="c-shadow pointer f8-mo f7-m f6-l h2 bn link dim br1 ph3 pv2 dib white"
                onClick={() => setGrantCredits(true)}
              >
                Grant Credits
              </button>
            </div>
          </div>
          {creditApprovalRequired?.required && (
            <div>
              <p className="tc">Approve pending credits</p>
              <div
                style={{ gap: "24px" }}
                className="flex justify-around items-center"
              >
                <button
                  style={{ background: "#6EB6FF" }}
                  className="c-shadow pointer f8-mo f7-m f6-l h2 bn link dim br1 ph3 pv2 dib white"
                  onClick={() => setApprovePendingCredits(true)}
                >
                  Approve {creditApprovalRequired?.creditsAsked} Credits
                </button>
                <button
                  style={{ border: "1px solid #6EB6FF", color: "#6EB6FF" }}
                  className="c-shadow pointer f8-mo f7-m f6-l h2 bn link dim br1 ph3 pv2 dib white"
                  onClick={() => setDeclinePendingCredits(true)}
                >
                  Decline Request
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {historyList && historyList.length > 0 && (
        <div>
          <h3 className="gray ma0 mb2 f4-l f6-m">Credits History</h3>
          <div className="overflow-auto bg-white br2 pa4 w-100">
            <table className="f6 w-100 mw8 center" cellSpacing="0">
              <thead>
                <tr>
                  <th className="fw6 bb b--black-20 tl pb3 pr3 bg-white">
                    Credit
                  </th>
                  <th className="fw6 bb b--black-20 tl pb3 pr3 bg-white">
                    Date
                  </th>
                  <th className="fw6 bb b--black-20 tl pb3 pr3 bg-white">
                    Time
                  </th>
                  <th className="fw6 bb b--black-20 tl pb3 pr3 bg-white">
                    Price
                  </th>
                </tr>
              </thead>
              <tbody className="lh-copy">
                {historyList?.map((val, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="pv3 pr3 bb b--black-20">{val.value}</td>
                      <td className="pv3 pr3 bb b--black-20">{val.date}</td>
                      <td className="pv3 pr3 bb b--black-20">{val.time}</td>
                      <td className="pv3 pr3 bb b--black-20">
                        &euro;{getPrice(val.value)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
