import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Backdrop } from "@mui/material";

const useStyles = makeStyles(() => ({
  img: {
    width: "90vh",
    height: "90vh",
  }
}));
const ResumePreview = ({ open, src, close }) => {
  const custom = useStyles();

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={close}>
      <embed title="CV Preview" src={src} className={custom.img} />
    </Backdrop>
  );
};

export default ResumePreview;
