import React, { useState, useEffect } from "react";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "../../styles/candidate.scss";
import Commentbox from "./Commentbox";
import ResumePreview from "./ResumePreview";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useMediaQuery } from "react-responsive";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { cv_def, cv_prefix, cv_prefix_gr } from "../../util/data/dummy";
import { url } from "../../util/data/static-data";
import Tooltip from "@mui/material/Tooltip";
import ViewCandidateDetails from "./ViewCandidateDetails";

export const Applied = React.memo(
  ({ candidate, jobid, setupdate, k, appliedtag, rectag, withtag }) => {
    const [userdetailspopup, setuserdetailspopup] = useState(false);
    const [overlay, setoverlay] = useState(false);
    const [isPreviewOpen, setisPreviewOpen] = useState(false);
    const ismobile = useMediaQuery({ query: `(max-width: 580px)` });

    const [lang, setlang] = useState([]);

    const [candidateid, setcandidateid] = useState(candidate);

    const [candidatesdata, setcandidatesdata] = useState({});
    const [cv, setcv] = useState("");

    const [shortclicked, setshortclicked] = useState(false);
    const [rejclicked, setrejclicked] = useState(false);
    const [userdeleted, setuserdeleted] = useState(false);

    const [dim, setdim] = useState(false);
    const [isloading, setisloading] = useState(false);

    useEffect(() => {
      if (candidateid !== "") {
        setisloading(true);
        fetch(`${url}/momatch/${candidateid}`)
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setcandidatesdata(res.data[0]);
              if (lang.length <= 0) {
                res.data[0].languages.map((val) =>
                  setlang((lang) => [
                    ...lang,
                    val.language + "( " + val.level + " )",
                  ]),
                );
              }
              setcandidateid(res.data[0]._id);
              setisloading(false);
            } else {
              setisloading(false);
              if (!res.success && res.msg === "not found") {
                setuserdeleted(true);
              }
            }
          });
      }
    }, [candidateid, lang]);

    useEffect(() => {
      if (shortclicked) {
        setdim(true);
        fetch(url + "/admin/recommend", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            timestamp: new Date(),
            job_id: jobid,
            candidate_id: candidateid,
            username: "Arvi",
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setupdate(true);
            }
          });
      }
      if (rejclicked) {
        setdim(true);
        fetch(url + "/admin/reject", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            timestamp: new Date(),
            job_id: jobid,
            candidate_id: candidateid,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setupdate(true);
            }
          });
      } // eslint-disable-next-line
    }, [jobid, rejclicked, shortclicked, candidateid]);

    useEffect(() => {
      if (candidatesdata.cv === undefined) return;
      setcv(
        `${candidatesdata.cv.english ? cv_prefix : cv_prefix_gr}${
          candidatesdata.cv.filename
        }`,
      );
    }, [candidatesdata]);

    return (
      <>
        <div
          onClick={() => {
            setuserdetailspopup(false);
            setoverlay(false);
          }}
          className={`fixed overlay top-0 bottom-0 left-0 right-0 ${
            overlay ? "active" : ""
          }`}
        ></div>
        {userdetailspopup && (
          <div
            style={{ top: "6rem", right: "8rem" }}
            className={`${
              userdetailspopup ? "" : "hide"
            } z-999 fixed flex justify-center w-80`}
          >
            <ViewCandidateDetails userdata={candidatesdata} />
          </div>
        )}
        {userdeleted ? (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid red" }}
            className={`w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <div>
              <p>Candidate has deleted their profile</p>
            </div>
            <p
              className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
              style={{
                border: `1px solid ${"red"}`,
                color: "red",
                top: "0.5rem",
                left: `${dim ? "4.6rem" : "1rem"}`,
              }}
            >
              {"Deleted"}
            </p>
          </div>
        ) : isloading ? (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid #6EB6FF" }}
            className={`w-100 flex justify-between ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <div className="flex flex-column w-20">
              <div className="skeleton skeleton-text mt3"></div>
              <div className="skeleton skeleton-text "></div>
              <div className="skeleton skeleton-text "></div>
            </div>
            <div className="flex flex-column ml6 mr5 w-10">
              <div className="skeleton skeleton-text mt3"></div>
              <div className="skeleton skeleton-text "></div>
              <div className="skeleton skeleton-text "></div>
            </div>
            <div className="flex-1 flex justify-around items-center">
              <div className="ico mb2 pointer flex flex-column items-center self-center">
                <div style={{ color: "gray" }}>
                  <InfoOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Info</p>
              </div>
              <div
                onClick={() => {}}
                className="ico mb2 pointer flex flex-column items-center self-center"
              >
                <div style={{ color: "gray" }}>
                  <DescriptionOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
              </div>
              <Commentbox />
            </div>
            <div className="h-100 flex-1 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
              <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center mt1">
                <button
                  onClick={() => {}}
                  style={{ color: "green" }}
                  className="bg-white c-shadow ml2-l ml2-m ml3 pointer mr2-l mr2-m mr0 w-60-l w-50-m w3 h2-l bn link dim br2 ph3 pv2-l pv1 dib white"
                >
                  <div className=" btnic flex f8-m f7-l f9-mo f8-m items-center justify-center">
                    <ThumbUpOutlinedIcon />
                    <span className="ml2">Shortlist</span>
                  </div>
                </button>
                <button
                  onClick={() => {}}
                  style={{ color: "red" }}
                  className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-60-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                >
                  <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                    <ThumbDownOutlinedIcon />
                    <span className="ml2">Reject</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            key={k}
            style={{
              borderRadius: "6px",
              borderLeft: dim
                ? shortclicked
                  ? "16px solid #078307"
                  : rejclicked
                  ? "16px solid #ff3f3f"
                  : ""
                : "3px solid #6EB6FF",
            }}
            className={`${
              dim ? "" : "dn"
            } w-100 relative ma1 dib ch bg-white pa3 mt3 br4}`}
          >
            <ResumePreview
              open={isPreviewOpen}
              close={() => setisPreviewOpen(!isPreviewOpen)}
              src={`${cv}#toolbar=0`}
            />
            <div className="flex justify-between items-start mt2-l mt0">
              <div className="flex flex-1 items-center">
                <div className="flex w-100 name_candidatebox flex-column">
                  <p className="truncate ma0 f4-l f5-m f7">
                    {candidatesdata.firstName + " " + candidatesdata.lastName}
                  </p>
                  <p
                    style={{ color: "#6EB6FF" }}
                    className="ma0 gray f7-l mt1 f7-m f8-mo"
                  >
                    {candidatesdata.careerLevel}
                  </p>
                  <p className="ma0 f8 mt1 f8-m f8-mo">
                    {`${candidatesdata.city}, ${candidatesdata.country}`}
                  </p>
                  <Tooltip title={lang.join(", ")}>
                    <p className="truncate ma0 gray f8 f9-m f10-mo mt1">
                      {lang.join(", ")}
                    </p>
                  </Tooltip>
                </div>
              </div>
              <div
                className={`flex flex-1 flex-column items-start ${
                  ismobile ? "hide" : ""
                }`}
              >
                <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                  Email:{" "}
                  <a
                    className="gray no-underline"
                    href={`mailto:${candidatesdata.email}`}
                  >
                    {candidatesdata.email}
                  </a>
                </p>
                <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                  Phone:
                  <a
                    className="gray no-underline"
                    href={`tel:${candidatesdata.contactNumber}`}
                  >
                    {" "}
                    {candidatesdata.contactNumber}{" "}
                  </a>
                </p>
                <Tooltip
                  title={
                    candidatesdata.skills
                      ? candidatesdata.skills.join(", ")
                      : candidatesdata.skills
                  }
                >
                  <p className="truncate name_candidatebox ma0 flex-1 mb3 gray f7 f8-m f8-mo ">
                    Skills:{" "}
                    {candidatesdata.skills
                      ? candidatesdata.skills.join(", ")
                      : candidatesdata.skills}
                  </p>
                </Tooltip>
              </div>
              <div className="flex-1 flex justify-around items-center">
                <div
                  onClick={() => {
                    setuserdetailspopup(true);
                    setoverlay(true);
                  }}
                  className="ico mb2 pointer flex flex-column items-center self-center"
                >
                  <div style={{ color: "gray" }}>
                    <InfoOutlinedIcon />
                  </div>
                  <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Info</p>
                </div>
                <div
                  onClick={() =>
                    navigator.userAgent.indexOf("Chrome") !== -1 &&
                    navigator.vendor.indexOf("Google Inc") !== -1
                      ? window.open(
                          `${cv}`,
                          "_blank",
                          "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                        )
                      : setisPreviewOpen((prev) => !prev)
                  }
                  className="ico mb2 pointer flex flex-column items-center self-center"
                >
                  <div style={{ color: "gray" }}>
                    <DescriptionOutlinedIcon />
                  </div>
                  <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
                </div>
                <Commentbox
                  candidatesdataid={candidateid}
                  jobid={jobid}
                  url={url}
                />
              </div>
              <div className="h-100 flex-1 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
                <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center mt1 items-center">
                  <button
                    onClick={() => {
                      setshortclicked(true);
                    }}
                    style={{ color: "green" }}
                    className="bg-white c-shadow ml2-l ml2-m ml3 pointer mr2-l mr2-m mr0 w-60-l w-50-m w3 h2-l bn link dim br2 ph3 pv2-l pv1 dib white"
                  >
                    <div className=" btnic flex f8-m f7-l f9-mo f8-m items-center justify-center">
                      <DoneOutlineIcon />
                      <span className="ml2">Client</span>
                    </div>
                  </button>
                  <button
                    onClick={() => setrejclicked(true)}
                    style={{ color: "red" }}
                    className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-60-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                  >
                    <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                      <ThumbDownOutlinedIcon />
                      <span className="ml2">Reject</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <p
              className={`${
                dim ? "absolute left-1 ma0 fw6 pl1 pr1 br2 f8" : "dn"
              }`}
              style={{
                border: `1px solid ${
                  dim
                    ? shortclicked
                      ? "green"
                      : rejclicked
                      ? "red"
                      : ""
                    : "#6EB6FF"
                }`,
                color: dim
                  ? shortclicked
                    ? "green"
                    : rejclicked
                    ? "red"
                    : ""
                  : "#6EB6FF",
                top: "0.5rem",
              }}
            >
              {dim
                ? shortclicked
                  ? "Shortlisted"
                  : rejclicked
                  ? "Rejected"
                  : ""
                : "Recomended by Moyyn"}
            </p>
            <p
              className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
              style={{
                border: `1px solid ${
                  withtag ? "red" : appliedtag || rectag ? "#6EB6FF" : "green"
                }`,
                color: `${
                  withtag ? "red" : appliedtag || rectag ? "#6EB6FF" : "green"
                }`,
                top: "0.5rem",
                left: `${dim ? (rectag ? "7.2rem" : "5.2rem") : "1rem"}`,
              }}
            >
              {withtag
                ? "Withdrawn"
                : appliedtag
                ? "Talent Pool"
                : rectag
                ? "Recommended by Moyyn"
                : "Applied"}
            </p>
            <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
              Registered on{" "}
              {new Date(candidatesdata.createdAt).toLocaleDateString()}
            </p>
          </div>
        )}
      </>
    );
  },
);

export const Candidatebox = React.memo(
  ({ candidate, jobid, setupdate, k, appliedtag, rectag, withtag, maildata }) => {
    const [userdetailspopup, setuserdetailspopup] = useState(false);
    const [overlay, setoverlay] = useState(false);
    const [isPreviewOpen, setisPreviewOpen] = useState(false);
    const ismobile = useMediaQuery({ query: `(max-width: 580px)` });
    // const {cv} = candidate;

    const [lang, setlang] = useState([]);
    const [candidateid, setcandidateid] = useState("");
    const [shortclicked, setshortclicked] = useState(false);
    const [rejclicked, setrejclicked] = useState(false);
    const [cv, setcv] = useState("");

    const [dim, setdim] = useState(false);

    useEffect(() => {
      setcandidateid(candidate._id);
      if (lang.length <= 0) {
        candidate.languages.map((val) =>
          setlang((lang) => [...lang, val.language + "( " + val.level + " )"]),
        );
      }
    }, [candidate, lang]);

    useEffect(() => {
      if (shortclicked) {
        setdim(true);
        fetch(url + "/admin/shortlist", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            timestamp: new Date(),
            job_id: jobid,
            candidate_id: candidateid,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              fetch(url + "/candidates/admin/ask", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  timestamp: new Date(),
                  job_id: jobid,
                  candidate_id: candidateid,
                  to: candidate.email,
                  cname: `${candidate.firstName} ${candidate.lastName}`,
                  company: maildata.company,
                  job_link: `https://jobs.moyyn.com/${maildata.company}/${encodeURIComponent(maildata.jobtittle)}-${encodeURIComponent(maildata.jobcode)}?jobType=Direct%20Client&jobid=${jobid}`,
                  job: maildata.jobtittle
                }),
              })
                .then((response) => response.json()).then((mailRes) => {
                  if (mailRes.success) {
                    setupdate(true);
                  }
                })
            }
          });
      }
      if (rejclicked) {
        setdim(true);
        fetch(url + "/admin/reject", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            timestamp: new Date(),
            job_id: jobid,
            candidate_id: candidateid,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setupdate(true);
            }
          });
      } // eslint-disable-next-line
    }, [candidateid, jobid, rejclicked, shortclicked]);

    useEffect(() => {
      let cvv = "";
      if (!candidate.cv.german && !candidate.cv.english) {
        cvv = cv_def;
      } else if (candidate.cv.english) {
        cvv = cv_prefix + candidate.cv.filename;
      } else {
        cvv = cv_prefix_gr + candidate.cv.filename;
      }
      setcv(cvv);
    }, [candidate]);

    return (
      <>
        <div
          onClick={() => {
            setuserdetailspopup(false);
            setoverlay(false);
          }}
          className={`fixed overlay top-0 bottom-0 left-0 right-0 ${
            overlay ? "active" : ""
          }`}
        ></div>
        {userdetailspopup && (
          <div
            style={{ top: "6rem", right: "8rem" }}
            className={`${
              userdetailspopup ? "" : "hide"
            } z-999 fixed flex justify-center w-80`}
          >
            <ViewCandidateDetails userdata={candidate} />
          </div>
        )}
        <div
          key={k}
          style={{
            borderRadius: "6px",
            borderLeft: dim
              ? shortclicked
                ? "16px solid #078307"
                : rejclicked
                ? "16px solid #ff3f3f"
                : ""
              : "3px solid #6EB6FF",
          }}
          className={`${
            dim ? "" : "dn"
          } w-100 relative ma1 dib ch bg-white pa3 mt3 br4}`}
        >
          <ResumePreview
            open={isPreviewOpen}
            close={() => setisPreviewOpen(!isPreviewOpen)}
            src={`${cv}#toolbar=0`}
          />
          <div className="flex justify-between items-start mt2-l mt0">
            <div className="flex flex-1 items-center">
              <div className="flex w-100 name_candidatebox flex-column">
                <p className="truncate ma0 f4-l f5-m f7">
                  {candidate.firstName + " " + candidate.lastName}
                </p>
                <p
                  style={{ color: "#6EB6FF" }}
                  className="ma0 gray f7-l mt1 f7-m f8-mo"
                >
                  {candidate.careerLevel}
                </p>
                <p className="ma0 f8 mt1 f8-m f8-mo">
                  {`${candidate.city}, ${candidate.country}`}
                </p>
                <Tooltip title={lang.join(", ")}>
                  <p className="truncate ma0 gray f8 f9-m f10-mo mt1">
                    {lang.join(", ")}
                  </p>
                </Tooltip>
              </div>
            </div>
            <div
              className={`flex flex-1 flex-column items-start ${
                ismobile ? "hide" : ""
              }`}
            >
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Email:{" "}
                <a
                  className="gray no-underline"
                  href={`mailto:${candidate.email}`}
                >
                  {candidate.email}
                </a>
              </p>
              <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                Phone:
                <a
                  className="gray no-underline"
                  href={`tel:${candidate.contactNumber}`}
                >
                  {" "}
                  {candidate.contactNumber}{" "}
                </a>
              </p>
              <Tooltip title={candidate.skills.join(", ")}>
                <p className="truncate name_candidatebox ma0 flex-1 mb3 gray f7 f8-m f8-mo ">
                  Skills: {candidate.skills.join(", ")}
                </p>
              </Tooltip>
            </div>
            <div className="flex-1 flex justify-around items-center">
              <div
                onClick={() => {
                  setuserdetailspopup(true);
                  setoverlay(true);
                }}
                className="ico mb2 pointer flex flex-column items-center self-center"
              >
                <div style={{ color: "gray" }}>
                  <InfoOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Info</p>
              </div>
              <div
                onClick={() =>
                  navigator.userAgent.indexOf("Chrome") !== -1 &&
                  navigator.vendor.indexOf("Google Inc") !== -1
                    ? window.open(
                        `${cv}`,
                        "_blank",
                        "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                      )
                    : setisPreviewOpen((prev) => !prev)
                }
                className="ico mb2 pointer flex flex-column items-center self-center"
              >
                <div style={{ color: "gray" }}>
                  <DescriptionOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
              </div>
              <Commentbox candidateid={candidateid} jobid={jobid} url={url} />
            </div>
            <div className="h-100 flex-1 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
              <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center mt1 items-center">
                <button
                  onClick={() => {
                    setshortclicked(true);
                  }}
                  style={{ color: "green" }}
                  className="bg-white c-shadow ml2-l ml2-m ml3 pointer mr2-l mr2-m mr0 w-60-l w-50-m w3 h2-l bn link dim br2 ph3 pv2-l pv1 dib white"
                >
                  <div className=" btnic flex f8-m f7-l f9-mo f8-m items-center justify-center">
                    <ThumbUpOutlinedIcon />
                    <span className="ml2">Ask To Apply</span>
                  </div>
                </button>
                <button
                  onClick={() => setrejclicked(true)}
                  style={{ color: "red" }}
                  className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-10 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                >
                  <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                    <ThumbDownOutlinedIcon />
                  </div>
                </button>
              </div>
            </div>
          </div>

          <p
            className={`${
              dim ? "absolute left-1 ma0 fw6 pl1 pr1 br2 f8" : "dn"
            }`}
            style={{
              border: `1px solid ${
                dim
                  ? shortclicked
                    ? "green"
                    : rejclicked
                    ? "red"
                    : ""
                  : "#6EB6FF"
              }`,
              color: dim
                ? shortclicked
                  ? "green"
                  : rejclicked
                  ? "red"
                  : ""
                : "#6EB6FF",
              top: "0.5rem",
            }}
          >
            {dim
              ? shortclicked
                ? "Shortlisted"
                : rejclicked
                ? "Rejected"
                : ""
              : "Recomended by Moyyn"}
          </p>
          <p
            className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
            style={{
              border: `1px solid ${
                withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
              }`,
              color: `${
                withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
              }`,
              top: "0.5rem",
              left: `${dim ? (rectag ? "7.2rem" : "5.2rem") : "1rem"}`,
            }}
          >
            {withtag
              ? "Withdrawn"
              : appliedtag
              ? "Applied"
              : rectag
              ? "Recommended by Moyyn"
              : "Talent Pool"}
          </p>
          <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
            Registered on {new Date(candidate.createdAt).toLocaleDateString()}
          </p>
        </div>
      </>
    );
  },
);

export const Shortlist = React.memo(
  ({
    candidate,
    jobid,
    setupdate,
    maildata,
    k,
    appliedtag,
    rectag,
    withtag,
  }) => {
    const [userdetailspopup, setuserdetailspopup] = useState(false);
    const [overlay, setoverlay] = useState(false);
    const [isPreviewOpen, setisPreviewOpen] = useState(false);
    const istab = useMediaQuery({ query: `(max-width: 1024px)` });
    const ismobile = useMediaQuery({ query: `(max-width: 580px)` });

    const [lang, setlang] = useState([]);

    const [candidateid, setcandidateid] = useState(candidate);

    const [candidatesdata, setcandidatesdata] = useState({});
    const [cv, setcv] = useState("");

    const [hireedclicked, sethireedclicked] = useState(false);
    const [rejclicked, setrejclicked] = useState(false);
    const [userdeleted, setuserdeleted] = useState(false);

    const [dim, setdim] = useState(false);
    const [isloading, setisloading] = useState(false);

    useEffect(() => {
      if (candidateid !== "") {
        setisloading(true);
        fetch(`${url}/momatch/${candidateid}`)
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setcandidatesdata(res.data[0]);
              if (lang.length <= 0) {
                res.data[0].languages.map((val) =>
                  setlang((lang) => [
                    ...lang,
                    val.language + "( " + val.level + " )",
                  ]),
                );
              }
              setcandidateid(res.data[0]._id);
              setisloading(false);
            } else {
              setisloading(false);
              if (!res.success && res.msg === "not found") {
                setuserdeleted(true);
              }
            }
          });
      }
    }, [candidateid, lang]);

    useEffect(() => {
      if (hireedclicked) {
        setdim(true);
        fetch(url + "/admin/recommend", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            timestamp: new Date(),
            job_id: jobid,
            candidate_id: candidateid,
            username: "Arvi",
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setupdate(true);
            }
          });
      }
      if (rejclicked) {
        setdim(true);
        fetch(url + "/admin/reject", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            timestamp: new Date(),
            job_id: jobid,
            candidate_id: candidateid,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setupdate(true);
            }
          });
      } // eslint-disable-next-line
    }, [jobid, rejclicked, hireedclicked, candidateid]);

    useEffect(() => {
      if (candidatesdata.cv === undefined) return;
      setcv(
        `${candidatesdata.cv.english ? cv_prefix : cv_prefix_gr}${
          candidatesdata.cv.filename
        }`,
      );
    }, [candidatesdata]);

    const mail_body = `Dear ${
      candidatesdata.firstName + " " + candidatesdata.lastName
    },%0d%0a %0d%0aGreetings from ${maildata.company}.%0d%0aI am ${
      maildata.username
    } from ${
      maildata.company
    } and your profile from Moyyn platform has been shortlisted by our company for the position of ${
      maildata.jobtittle
    }. We liked your application and would be interested in knowing more about you.%0d%0aJob Url: ${
      maildata.joblink
    }%0d%0aPlease let me know whether you are interested in this position so that we can schedule an interview.%0d%0a %0d%0aBest regards,%0d%0a${
      maildata.username
    }%0d%0aSourced from Moyyn`;
    const mail_sub = `You have been shortlisted for interview by ${maildata.company} via Moyyn`;

    return (
      <>
        <div
          onClick={() => {
            setuserdetailspopup(false);
            setoverlay(false);
          }}
          className={`fixed overlay top-0 bottom-0 left-0 right-0 ${
            overlay ? "active" : ""
          }`}
        ></div>
        {userdetailspopup && (
          <div
            style={{ top: "6rem", right: "8rem" }}
            className={`${
              userdetailspopup ? "" : "hide"
            } z-999 fixed flex justify-center w-80`}
          >
            <ViewCandidateDetails userdata={candidatesdata} />
          </div>
        )}
        {userdeleted ? (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid red" }}
            className={`w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <div>
              <p>Candidate has deleted their profile</p>
            </div>
            <p
              className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
              style={{
                border: `1px solid ${"red"}`,
                color: "red",
                top: "0.5rem",
                left: `${dim ? "4.6rem" : "1rem"}`,
              }}
            >
              {"Deleted"}
            </p>
          </div>
        ) : isloading ? (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid #6EB6FF" }}
            className={`w-100 flex justify-between ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <div className="flex flex-column w-20">
              <div className="skeleton skeleton-text mt3"></div>
              <div className="skeleton skeleton-text "></div>
              <div className="skeleton skeleton-text "></div>
            </div>
            <div className="flex flex-column ml6 mr5 w-10">
              <div className="skeleton skeleton-text mt3"></div>
              <div className="skeleton skeleton-text "></div>
              <div className="skeleton skeleton-text "></div>
            </div>
            <div className="flex-1 flex justify-around items-center">
              <div className="ico mb2 pointer flex flex-column items-center self-center">
                <div style={{ color: "gray" }}>
                  <InfoOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Info</p>
              </div>
              <div
                onClick={() => {}}
                className="ico mb2 pointer flex flex-column items-center self-center"
              >
                <div style={{ color: "gray" }}>
                  <DescriptionOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
              </div>
              <Commentbox />
            </div>
            <div className="h-100 flex-1 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
              <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center mt1">
                <button
                  onClick={() => {}}
                  style={{ color: "green" }}
                  className="bg-white c-shadow ml2-l ml2-m ml3 pointer mr2-l mr2-m mr0 w-60-l w-50-m w3 h2-l bn link dim br2 ph3 pv2-l pv1 dib white"
                >
                  <div className=" btnic flex f8-m f7-l f9-mo f8-m items-center justify-center">
                    <ThumbUpOutlinedIcon />
                    <span className="ml2">Shortlist</span>
                  </div>
                </button>
                <button
                  onClick={() => {}}
                  style={{ color: "red" }}
                  className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-60-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                >
                  <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                    <ThumbDownOutlinedIcon />
                    <span className="ml2">Reject</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            key={k}
            style={{
              borderRadius: "6px",
              borderLeft: dim
                ? rejclicked
                  ? "16px solid #ff3f3f"
                  : hireedclicked
                  ? "16px solid green"
                  : ""
                : "3px solid #6EB6FF",
            }}
            className={`${
              dim ? "" : "dn"
            } w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <ResumePreview
              open={isPreviewOpen}
              close={() => setisPreviewOpen(!isPreviewOpen)}
              src={`${cv}#toolbar=0`}
            />
            <div className="flex justify-between items-start mt2-l mt0">
              <div className="flex flex-1 name_candidatebox items-center">
                <div className="flex name_candidatebox flex-column">
                  <p className="truncate ma0 f4-l f5-m f7">
                    {candidatesdata.firstName + " " + candidatesdata.lastName}
                  </p>
                  <p
                    style={{ color: "#6EB6FF" }}
                    className="ma0 gray f7-l mt1 f7-m f8-mo"
                  >
                    {candidatesdata.careerLevel}
                  </p>
                  <p className="ma0 f8 mt1 f8-m f8-mo">
                    {`${candidatesdata.city}, ${candidatesdata.country}`}
                  </p>
                  <Tooltip title={lang.join(", ")}>
                    <p className="truncate ma0 gray f8 f9-m f10-mo mt1">
                      {lang.join(", ")}
                    </p>
                  </Tooltip>
                </div>
              </div>
              <div
                className={`flex flex-1 flex-column items-start ${
                  ismobile ? "hide" : ""
                }`}
              >
                <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                  Email:{" "}
                  <a
                    className="gray no-underline"
                    href={`mailto:${candidatesdata.email}`}
                  >
                    {candidatesdata.email}
                  </a>
                </p>
                <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                  Phone:
                  <a
                    className="gray no-underline"
                    href={`tel:${candidatesdata.contactNumber}`}
                  >
                    {" "}
                    {candidatesdata.contactNumber}{" "}
                  </a>
                </p>
                <Tooltip
                  title={
                    candidatesdata.skills
                      ? candidatesdata.skills.join(", ")
                      : candidatesdata.skills
                  }
                >
                  <p className="truncate name_candidatebox ma0 flex-1 mb3 gray f7 f8-m f8-mo ">
                    Skills:{" "}
                    {candidatesdata.skills
                      ? candidatesdata.skills.join(", ")
                      : candidatesdata.skills}
                  </p>
                </Tooltip>
              </div>
              <div className="flex-1 flex justify-around items-center">
                <div
                  onClick={() => {
                    setuserdetailspopup(true);
                    setoverlay(true);
                  }}
                  className="ico mb2 pointer flex flex-column items-center self-center"
                >
                  <div style={{ color: "gray" }}>
                    <InfoOutlinedIcon />
                  </div>
                  <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Info</p>
                </div>
                <div
                  onClick={() =>
                    navigator.userAgent.indexOf("Chrome") !== -1 &&
                    navigator.vendor.indexOf("Google Inc") !== -1
                      ? window.open(
                          `${cv}`,
                          "_blank",
                          "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                        )
                      : setisPreviewOpen((prev) => !prev)
                  }
                  className="ico mb2 pointer flex flex-column items-center self-center"
                >
                  <div style={{ color: "gray" }}>
                    <DescriptionOutlinedIcon />
                  </div>
                  <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
                </div>
                <Commentbox candidateid={candidateid} jobid={jobid} url={url} />
                <div
                  onClick={(e) => {
                    window.location.href = `mailto:${candidatesdata.email}?subject=${mail_sub}&body=${mail_body}`;
                    e.preventDefault();
                  }}
                  className={`ico mb2 pointer flex flex-column items-center self-center ${
                    istab ? "hide" : ""
                  }`}
                >
                  <div style={{ color: "gray" }}>
                    <MailOutlinedIcon />
                  </div>
                  <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Mail</p>
                </div>
              </div>
              <div className="h-100 flex-1 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
                <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center mt1">
                  <button
                    onClick={() => sethireedclicked(true)}
                    style={{ color: "green" }}
                    className="bg-white c-shadow ml2-l ml2-m ml3 pointer mr2-l mr2-m mr0 w-60-l w-50-m w3 h2-l bn link dim br2 ph4 pv2-l pv1 dib white"
                  >
                    <div className=" btnic flex f8-m f7-l f9-mo f8-m items-center justify-center">
                      <DoneOutlineIcon />
                      <span className="ml2">Client</span>
                    </div>
                  </button>
                  <button
                    onClick={() => setrejclicked(true)}
                    style={{ color: "red" }}
                    className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-60-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                  >
                    <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                      <HighlightOffIcon />
                      <span className="ml2">Reject</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <p
              className={`${
                dim ? "absolute left-1 ma0 fw6 pl1 pr1 br2 f8" : "dn"
              }`}
              style={{
                border: `1px solid ${
                  dim
                    ? rejclicked
                      ? "red"
                      : hireedclicked
                      ? "green"
                      : ""
                    : "#6EB6FF"
                }`,
                color: dim
                  ? rejclicked
                    ? "red"
                    : hireedclicked
                    ? "green"
                    : ""
                  : "#6EB6FF",
                top: "0.5rem",
              }}
            >
              {dim
                ? rejclicked
                  ? "Rejected"
                  : hireedclicked
                  ? "Hired"
                  : ""
                : ""}
            </p>
            <p
              className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
              style={{
                border: `1px solid ${
                  withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
                }`,
                color: `${
                  withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
                }`,
                top: "0.5rem",
                left: `${dim ? (rectag ? "7.2rem" : "5.2rem") : "1rem"}`,
              }}
            >
              {withtag
                ? "Withdrawn"
                : appliedtag
                ? "Applied"
                : rectag
                ? "Recommended by Moyyn"
                : "Talent Pool"}
            </p>
            <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
              Registered on{" "}
              {new Date(candidatesdata.createdAt).toLocaleDateString()}
            </p>
          </div>
        )}
      </>
    );
  },
);

export const Rejected = React.memo(
  ({ candidate, jobid, setupdate, k, appliedtag, rectag, withtag }) => {
    const [userdetailspopup, setuserdetailspopup] = useState(false);
    const [overlay, setoverlay] = useState(false);
    const [isPreviewOpen, setisPreviewOpen] = useState(false);
    const ismobile = useMediaQuery({ query: `(max-width: 580px)` });

    const [lang, setlang] = useState([]);

    const [candidateid, setcandidateid] = useState(candidate);

    const [candidatesdata, setcandidatesdata] = useState({});
    const [cv, setcv] = useState("");

    const [shortclicked, setshortclicked] = useState(false);

    const [userdeleted, setuserdeleted] = useState(false);

    const [dim, setdim] = useState(false);
    const [isloading, setisloading] = useState(false);

    useEffect(() => {
      if (candidateid !== "") {
        setisloading(true);
        fetch(url + "/momatch/" + candidateid)
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setcandidatesdata(res.data[0]);
              if (lang.length <= 0) {
                res.data[0].languages.map((val) =>
                  setlang((lang) => [
                    ...lang,
                    val.language + "( " + val.level + " )",
                  ]),
                );
              }
              setcandidateid(res.data[0]._id);
              setisloading(false);
            } else {
              setisloading(false);
              if (!res.success && res.msg === "not found") {
                setuserdeleted(true);
              }
            }
          });
      }
    }, [candidateid, lang]);

    useEffect(() => {
      if (shortclicked) {
        setdim(true);
        fetch(url + "/admin/shortlist", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            timestamp: new Date(),
            job_id: jobid,
            candidate_id: candidateid,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setupdate(true);
            }
          });
      }
    }, [jobid, shortclicked, candidateid, setupdate]);

    useEffect(() => {
      if (candidatesdata.cv === undefined) return;
      setcv(
        `${candidatesdata.cv.english ? cv_prefix : cv_prefix_gr}${
          candidatesdata.cv.filename
        }`,
      );
    }, [candidatesdata]);

    return (
      <>
        <div
          onClick={() => {
            setuserdetailspopup(false);
            setoverlay(false);
          }}
          className={`fixed overlay top-0 bottom-0 left-0 right-0 ${
            overlay ? "active" : ""
          }`}
        ></div>
        {userdetailspopup && (
          <div
            style={{ top: "6rem", right: "8rem" }}
            className={`${
              userdetailspopup ? "" : "hide"
            } z-999 fixed flex justify-center w-80`}
          >
            <ViewCandidateDetails userdata={candidatesdata} />
          </div>
        )}
        {userdeleted ? (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid red" }}
            className={`w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <div>
              <p>Candidate has deleted their profile</p>
            </div>
            <p
              className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
              style={{
                border: `1px solid ${"red"}`,
                color: "red",
                top: "0.5rem",
                left: `${dim ? "4.6rem" : "1rem"}`,
              }}
            >
              {"Deleted"}
            </p>
          </div>
        ) : isloading ? (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid #6EB6FF" }}
            className={`w-100 flex justify-between ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <div className="flex flex-column w-20">
              <div className="skeleton skeleton-text mt3"></div>
              <div className="skeleton skeleton-text "></div>
              <div className="skeleton skeleton-text "></div>
            </div>
            <div className="flex flex-column ml6 mr5 w-10">
              <div className="skeleton skeleton-text mt3"></div>
              <div className="skeleton skeleton-text "></div>
              <div className="skeleton skeleton-text "></div>
            </div>
            <div className="flex-1 flex justify-around items-center">
              <div className="ico mb2 pointer flex flex-column items-center self-center">
                <div style={{ color: "gray" }}>
                  <InfoOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Info</p>
              </div>
              <div
                onClick={() => {}}
                className="ico mb2 pointer flex flex-column items-center self-center"
              >
                <div style={{ color: "gray" }}>
                  <DescriptionOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
              </div>
              <Commentbox />
            </div>
            <div className="h-100 flex-1 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
              <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center mt1">
                <button
                  onClick={() => {}}
                  style={{ color: "green" }}
                  className="bg-white c-shadow ml2-l ml2-m ml3 pointer mr2-l mr2-m mr0 w-60-l w-50-m w3 h2-l bn link dim br2 ph3 pv2-l pv1 dib white"
                >
                  <div className=" btnic flex f8-m f7-l f9-mo f8-m items-center justify-center">
                    <ThumbUpOutlinedIcon />
                    <span className="ml2">Shortlist</span>
                  </div>
                </button>
                <button
                  onClick={() => {}}
                  style={{ color: "red" }}
                  className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-60-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                >
                  <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                    <ThumbDownOutlinedIcon />
                    <span className="ml2">Reject</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            key={k}
            style={{
              borderRadius: "6px",
              borderLeft:
                dim || shortclicked
                  ? "16px solid #078307"
                  : "3px solid #6EB6FF",
            }}
            className={`${
              dim ? "" : "dn"
            } w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <ResumePreview
              open={isPreviewOpen}
              close={() => setisPreviewOpen(!isPreviewOpen)}
              src={`${cv}`}
            />
            <div className="flex justify-between items-start mt2-l mt0">
              <div className="flex-1 flex items-center">
                <div className="flex name_candidatebox flex-column">
                  <p className="truncate ma0 f4-l f5-m f7">
                    {candidatesdata.firstName + " " + candidatesdata.lastName}
                  </p>
                  <p
                    style={{ color: "#6EB6FF" }}
                    className="ma0 gray f7-l mt1 f7-m f8-mo"
                  >
                    {candidatesdata.careerLevel}
                  </p>
                  <p className="ma0 f8 mt1 f8-m f8-mo">
                    {`${candidatesdata.city}, ${candidatesdata.country}`}
                  </p>
                  <Tooltip title={lang.join(", ")}>
                    <p className="truncate ma0 gray f8 f9-m f10-mo mt1">
                      {lang.join(", ")}
                    </p>
                  </Tooltip>
                </div>
              </div>
              <div
                className={`flex-1 flex flex-column items-start ${
                  ismobile ? "hide" : ""
                }`}
              >
                <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                  Email:{" "}
                  <a
                    className="gray no-underline"
                    href={`mailto:${candidatesdata.email}`}
                  >
                    {candidatesdata.email}
                  </a>
                </p>
                <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                  Phone:
                  <a
                    className="gray no-underline"
                    href={`tel:${candidatesdata.contactNumber}`}
                  >
                    {" "}
                    {candidatesdata.contactNumber}{" "}
                  </a>
                </p>
                <Tooltip
                  title={
                    candidatesdata.skills
                      ? candidatesdata.skills.join(", ")
                      : candidatesdata.skills
                  }
                >
                  <p className="truncate name_candidatebox ma0 flex-1 mb3 gray f7 f8-m f8-mo ">
                    Skills:{" "}
                    {candidatesdata.skills
                      ? candidatesdata.skills.join(", ")
                      : candidatesdata.skills}
                  </p>
                </Tooltip>
              </div>
              <div className="flex-1 flex justify-around items-center">
                <div
                  onClick={() => {
                    setuserdetailspopup(true);
                    setoverlay(true);
                  }}
                  className="ico mb2 pointer flex flex-column items-center self-center"
                >
                  <div style={{ color: "gray" }}>
                    <InfoOutlinedIcon />
                  </div>
                  <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Info</p>
                </div>
                <div
                  onClick={() =>
                    navigator.userAgent.indexOf("Chrome") !== -1 &&
                    navigator.vendor.indexOf("Google Inc") !== -1
                      ? window.open(
                          `${cv}`,
                          "_blank",
                          "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                        )
                      : setisPreviewOpen((prev) => !prev)
                  }
                  className="ico mb2 pointer flex flex-column items-center self-center"
                >
                  <div style={{ color: "gray" }}>
                    <DescriptionOutlinedIcon />
                  </div>
                  <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
                </div>
                <Commentbox candidateid={candidateid} jobid={jobid} url={url} />
              </div>
              <div className="flex-1 h-100 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
                <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center mt1">
                  <button
                    onClick={() => setshortclicked(true)}
                    style={{ color: "#6EB6FF" }}
                    className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-80-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                  >
                    <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                      <KeyboardBackspaceOutlinedIcon />
                      <span className="ml2">
                        Shortlist{ismobile ? "" : " Again"}
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <p
              className={`${
                dim ? "absolute left-1 ma0 fw6 pl1 pr1 br2 f8" : "dn"
              }`}
              style={{
                border: `1px solid ${
                  dim || shortclicked ? "green" : "#6EB6FF"
                }`,
                color: dim || shortclicked ? "green" : "#6EB6FF",
                top: "0.5rem",
              }}
            >
              {dim || shortclicked ? "Shortlisted" : "Recomended by Moyyn"}
            </p>
            <p
              className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
              style={{
                border: `1px solid ${
                  withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
                }`,
                color: `${
                  withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
                }`,
                top: "0.5rem",
                left: `${dim ? (rectag ? "7.2rem" : "5.2rem") : "1rem"}`,
              }}
            >
              {withtag
                ? "Withdrawn"
                : appliedtag
                ? "Applied"
                : rectag
                ? "Recommended by Moyyn"
                : "Talent Pool"}
            </p>
            <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
              Registered on{" "}
              {new Date(candidatesdata.createdAt).toLocaleDateString()}
            </p>
          </div>
        )}
      </>
    );
  },
);

export const Selected = React.memo(
  ({ candidate, jobid, k, appliedtag, rectag, withtag }) => {
    const [userdetailspopup, setuserdetailspopup] = useState(false);
    const [overlay, setoverlay] = useState(false);
    const [isPreviewOpen, setisPreviewOpen] = useState(false);
    const ismobile = useMediaQuery({ query: `(max-width: 580px)` });

    const [lang, setlang] = useState([]);

    const [candidateid] = useState(candidate);

    const [candidatesdata, setcandidatesdata] = useState({});
    const [cv, setcv] = useState("");
    const [userdeleted, setuserdeleted] = useState(false);

    const [isloading, setisloading] = useState(false);

    useEffect(() => {
      if (candidateid !== "") {
        setisloading(true);
        fetch(url + "/momatch/" + candidateid)
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setcandidatesdata(res.data[0]);
              if (lang.length <= 0) {
                res.data[0].languages.map((val) =>
                  setlang((lang) => [
                    ...lang,
                    val.language + "( " + val.level + " )",
                  ]),
                );
              }
              setisloading(false);
            } else {
              setisloading(false);
              if (!res.success && res.msg === "not found") {
                setuserdeleted(true);
              }
            }
          });
      }
    }, [candidateid, lang]);

    useEffect(() => {
      if (candidatesdata.cv === undefined) return;
      setcv(
        `${candidatesdata.cv.english ? cv_prefix : cv_prefix_gr}${
          candidatesdata.cv.filename
        }`,
      );
    }, [candidatesdata]);

    return (
      <>
        <div
          onClick={() => {
            setuserdetailspopup(false);
            setoverlay(false);
          }}
          className={`fixed overlay top-0 bottom-0 left-0 right-0 ${
            overlay ? "active" : ""
          }`}
        ></div>
        {userdetailspopup && (
          <div
            style={{ top: "6rem", right: "8rem" }}
            className={`${
              userdetailspopup ? "" : "hide"
            } z-999 fixed flex justify-center w-80`}
          >
            <ViewCandidateDetails userdata={candidatesdata} />
          </div>
        )}
        {userdeleted ? (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid red" }}
            className={`w-100 relative ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <div>
              <p>Candidate has deleted their profile</p>
            </div>
            <p
              className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
              style={{
                border: `1px solid ${"red"}`,
                color: "red",
                top: "0.5rem",
                left: "1rem",
              }}
            >
              {"Deleted"}
            </p>
          </div>
        ) : isloading ? (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid #6EB6FF" }}
            className={`w-100 flex justify-between ma1 dib ch bg-white pa3 mt3 br3}`}
          >
            <div className="flex flex-column w-20">
              <div className="skeleton skeleton-text mt3"></div>
              <div className="skeleton skeleton-text "></div>
              <div className="skeleton skeleton-text "></div>
            </div>
            <div className="flex flex-column ml6 mr5 w-10">
              <div className="skeleton skeleton-text mt3"></div>
              <div className="skeleton skeleton-text "></div>
              <div className="skeleton skeleton-text "></div>
            </div>
            <div className="flex-1 flex justify-around items-center">
              <div className="ico mb2 pointer flex flex-column items-center self-center">
                <div style={{ color: "gray" }}>
                  <InfoOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Info</p>
              </div>
              <div
                onClick={() => {}}
                className="ico mb2 pointer flex flex-column items-center self-center"
              >
                <div style={{ color: "gray" }}>
                  <DescriptionOutlinedIcon />
                </div>
                <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
              </div>
              <Commentbox />
            </div>
            <div className="h-100 flex-1 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
              <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center mt1">
                <button
                  onClick={() => {}}
                  style={{ color: "green" }}
                  className="bg-white c-shadow ml2-l ml2-m ml3 pointer mr2-l mr2-m mr0 w-60-l w-50-m w3 h2-l bn link dim br2 ph3 pv2-l pv1 dib white"
                >
                  <div className=" btnic flex f8-m f7-l f9-mo f8-m items-center justify-center">
                    <ThumbUpOutlinedIcon />
                    <span className="ml2">Shortlist</span>
                  </div>
                </button>
                <button
                  onClick={() => {}}
                  style={{ color: "red" }}
                  className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-60-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                >
                  <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                    <ThumbDownOutlinedIcon />
                    <span className="ml2">Reject</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            key={k}
            style={{ borderRadius: "6px", borderLeft: "3px solid #6EB6FF" }}
            className={`w-100 relative ma1 dib ch bg-white pa3 mt3 br3`}
          >
            <ResumePreview
              open={isPreviewOpen}
              close={() => setisPreviewOpen(!isPreviewOpen)}
              src={`${cv}#toolbar=0`}
            />
            <div className="flex justify-between items-start mt2-l mt0">
              <div className="flex-1 flex items-center">
                <div className="flex name_candidatebox flex-column">
                  <p className="truncate ma0 f4-l f5-m f7">
                    {candidatesdata.firstName + " " + candidatesdata.lastName}
                  </p>
                  <p
                    style={{ color: "#6EB6FF" }}
                    className="ma0 gray f7-l mt1 f7-m f8-mo"
                  >
                    {candidatesdata.careerLevel}
                  </p>
                  <p className="ma0 f8 mt1 f8-m f8-mo">
                    {`${candidatesdata.city}, ${candidatesdata.country}`}
                  </p>
                  <Tooltip title={lang.join(", ")}>
                    <p className="truncate ma0 gray f8 f9-m f10-mo mt1">
                      {lang.join(", ")}
                    </p>
                  </Tooltip>
                </div>
              </div>
              <div
                className={`flex-1 flex flex-column items-start ${
                  ismobile ? "hide" : ""
                }`}
              >
                <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                  Email:{" "}
                  <a
                    className="gray no-underline"
                    href={`mailto:${candidatesdata.email}`}
                  >
                    {candidatesdata.email}
                  </a>
                </p>
                <p className="ma0 flex-1 mb3 gray f7 f8-m f8-mo">
                  Phone:
                  <a
                    className="gray no-underline"
                    href={`tel:${candidatesdata.contactNumber}`}
                  >
                    {" "}
                    {candidatesdata.contactNumber}{" "}
                  </a>
                </p>
                <Tooltip
                  title={
                    candidatesdata.skills
                      ? candidatesdata.skills.join(", ")
                      : candidatesdata.skills
                  }
                >
                  <p className="truncate name_candidatebox ma0 flex-1 mb3 gray f7 f8-m f8-mo ">
                    Skills:{" "}
                    {candidatesdata.skills
                      ? candidatesdata.skills.join(", ")
                      : candidatesdata.skills}
                  </p>
                </Tooltip>
              </div>
              <div className="flex-1 flex justify-around items-center">
                <div
                  onClick={() => {
                    setuserdetailspopup(true);
                    setoverlay(true);
                  }}
                  className="ico mb2 pointer flex flex-column items-center self-center"
                >
                  <div style={{ color: "gray" }}>
                    <InfoOutlinedIcon />
                  </div>
                  <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">Info</p>
                </div>
                <div
                  onClick={() =>
                    navigator.userAgent.indexOf("Chrome") !== -1 &&
                    navigator.vendor.indexOf("Google Inc") !== -1
                      ? window.open(
                          `${cv}`,
                          "_blank",
                          "toolbar=no,scrollbars=yes,resizable=no,top=500,left=500,width=650,height=800",
                        )
                      : setisPreviewOpen((prev) => !prev)
                  }
                  className="ico mb2 pointer flex flex-column items-center self-center"
                >
                  <div style={{ color: "gray" }}>
                    <DescriptionOutlinedIcon />
                  </div>
                  <p className="ma0 flex-1 mt1 gray f8 f9-m f9-mo">View CV</p>
                </div>
                <Commentbox candidateid={candidateid} jobid={jobid} url={url} />
              </div>
              <div className="flex-1 h-100 w-25-l w-40-m w-30 pr4 mb2 mt3-l">
                <div className="flex justify-center flex-row-l flex-row-m flex-column justify-center items-center mt1">
                  <button
                    onClick={(e) => {
                      window.location.href = `mailto:${candidatesdata.email}?subject=Job at Moyyn&body=Hello, thsnks for applying`;
                      e.preventDefault();
                    }}
                    style={{ color: "#6EB6FF" }}
                    className="bg-white pointer ml2-l ml2-m ml3 c-shadow w-80-l w-50-m w3 h2-l mt2  mt0-l mt0-m bn link dim br2 ph3 pv2-l pv1 dib"
                  >
                    <div className="btnic flex f9-mo f8-m f7-l f8-m  items-center justify-center">
                      <MailOutlinedIcon />
                      <span className="ml2">Send Mail</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <p className="absolute top-1 right-1 ma0 br2 gray f8 f9-m f10-mo">
              Registered on{" "}
              {new Date(candidatesdata.createdAt).toLocaleDateString()}
            </p>
            <p
              className={`absolute ma0 fw6 pl1 pr1 br2 f8`}
              style={{
                border: `1px solid ${
                  withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
                }`,
                color: `${
                  withtag ? "red" : appliedtag || rectag ? "green" : "#6EB6FF"
                }`,
                top: "0.5rem",
                left: `${"1rem"}`,
              }}
            >
              {withtag
                ? "Withdrwan"
                : appliedtag
                ? "Applied"
                : rectag
                ? "Recommended by Moyyn"
                : "Talent Pool"}
            </p>
          </div>
        )}
      </>
    );
  },
);
