export const postjobinitialvalues = {
  careerLevel: "",
  Industries: [],
  Skills: [],
  skills_secondary: [],
  workExperience: [],
  Languages: [],
};

export const filterinitialvalues = {
  careerLevel: "",
  country: "",
  city: "",
  industries: [],
  skills: [],
  skills_secondary: [],
  workExperience: [],
  languages: [],
};

export const signupforminitialvalues = {
  company: "",
  name: "",
  website: "",
  location: "",
  email: "",
  phone: "",
  password: "",
};
