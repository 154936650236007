import React, { useState, useEffect } from "react";
import { FieldArray } from "formik";
import { Chip, TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import FormError from "./FormError";
import PDLJS from "peopledatalabs";

const PDLFetchField = ({ name, placeholder, pdlName, mskill = true }) => {
  const [fetchedData, setFetchedData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const PDLJSClient = new PDLJS({
    apiKey: process.env.REACT_APP_PEOPLE_DATA_LABS,
  });

  const handleInputChange = (event) => {
    if (event !== null) {
      setInputValue(event.target.value);
    }
  };

  useEffect(() => {
    if (!inputValue) return;
    const timer = setTimeout(() => {
      PDLJSClient.autocomplete({
        field: pdlName,
        text: inputValue,
        size: 10,
      }).then((res) => setFetchedData(res.data.map((job) => job.name)));
    }, 1000);
    return () => clearTimeout(timer);
  }, [inputValue, setInputValue]);

  return (
    <FieldArray name={name}>
      {({ form, push, remove }) => {
        const handleChange = (event, values) => {
          event.stopPropagation();
          if (event.keyCode === 8) {
            remove(values.length);
          } else if (event.keyCode === 13) {
            push(values[values.length - 1]);
            setInputValue("");
          } else {
            console.log("can't add value");
          }
        };

        return (
          <React.Fragment>
            <Autocomplete
              onInputChange={handleInputChange}
              inputValue={inputValue}
              multiple
              freeSolo
              options={fetchedData}
              onChange={handleChange}
              value={
                name === "Skills" || name === "skills_secondary"
                  ? [
                      ...new Set(
                        mskill
                          ? form.values.Skills
                          : form.values.skills_secondary,
                      ),
                    ]
                  : [...new Set(form.values.relevantJobTitle)]
              }
              clearIcon={true}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={option}
                    key={option}
                    onDelete={() => remove(index)}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={placeholder} />
              )}
            />
            <FormError name={name} />
          </React.Fragment>
        );
      }}
    </FieldArray>
  );
};

export default PDLFetchField;
