import React, { useState, useEffect } from "react";
import "tachyons";
import renderHTML from "react-render-html";

export default function ViewJob({ jobdata }) {
  const [lang, setlang] = useState([]);

  const MakeURL = (url) => {
    const pattern = /^((http|https|ftp):\/\/)/;
    if (!pattern.test(url)) {
      return "https://" + url;
    } else return url;
  };

  useEffect(() => {
    jobdata.Languages.map((val) => setlang((lang) => [...lang, val.language]));
  }, [jobdata]);

  return (
    <div
      style={{ height: "600px", overflow: "auto" }}
      className={`bg-white br4 remove-scrollbar pa4 w-80`}
    >
      <h4>Job Title</h4>
      <p className="gray f6 lh-copy">{jobdata.jobTitle}</p>
      <h4>Job Url</h4>
      <p className="gray f6 lh-copy">
        <a
          href={MakeURL(jobdata.jobUrl)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {MakeURL(jobdata.jobUrl)}
        </a>
      </p>
      <h4>Job Description</h4>
      <p className="gray f6 lh-copy">{renderHTML(jobdata.description)}</p>
      <h4>Job Requirements</h4>
      <p className="gray f6 lh-copy"> {renderHTML(jobdata.requirements)}</p>
      <h4>Job Code</h4>
      <p className="gray f6 lh-copy">{jobdata.jobCode}</p>
      <h4>Location</h4>
      <p className="gray f6 lh-copy">{`${jobdata.city}, ${jobdata.country}, ${jobdata.postcode}`}</p>
      <h4>Industries</h4>
      <p className="gray f6 lh-copy">{jobdata.Industries.join(", ")}</p>
      <h4>Career Level</h4>
      <p className="gray f6 lh-copy">{jobdata.careerLevel}</p>
      <h4>Work Experience</h4>
      <div>
        {jobdata.workExperience.map((val, idx) => {
          return (
            <div className="" key={idx}>
              <p className="gray f6 lh-copy">Category: {val.Category}</p>
              <p className="gray f6 lh-copy">Role: {val.Role}</p>
              <p className="gray f6 lh-copy">Experience: {val.Experience}</p>
            </div>
          );
        })}
      </div>
      <h4>Languages</h4>
      <p className="gray f6 lh-copy">{lang.join(", ")}</p>
      <h4>Primary Skills</h4>
      <p className="gray f6 lh-copy">{jobdata.Skills.join(", ")}</p>
      <h4>Secondary Skills</h4>
      <p className="gray f6 lh-copy">
        {jobdata.skills_secondary.length <= 0
          ? "No Skills"
          : jobdata.skills_secondary.join(", ")}
      </p>
      <h4>Start Date</h4>
      <p className="gray f6 lh-copy">{jobdata.Date}</p>
      <h4>Salary</h4>
      <p className="gray f6 lh-copy">{`${jobdata.from} - ${jobdata.to} (${jobdata.currency})`}</p>
      <h4>Wiiling ro recruit from other countries?</h4>
      <p className="gray f6 lh-copy">{jobdata.othercountries ? "Yes" : "No"}</p>
    </div>
  );
}
