import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import "tachyons";
import { url } from "../../util/data/static-data";

export default function ApprovedCompany({
  approvedcompanydata,
  free,
  pps,
  seterror,
  setreload,
}) {
  const [momatch, setmomatch] = useState(true);
  const [momatch1, setmomatch1] = useState(true);
  const [stopcandidates, setstopcandidates] = useState(true);

  const [approveclicked, setapproveclicked] = useState(false);
  const [approvefor, setapprovefor] = useState("");

  const [statusclicked, setstatusclicked] = useState(false);
  const [status, setstatus] = useState("");

  const [PDLTrigger, setPDLTrigger] = useState(false);
  const [deleteClient, setdeleteClient] = useState(false);


  const history = useHistory();

  const triggerPDLToggle = () => {
    fetch(`${url}/ispdl/toggle`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: approvedcompanydata._id,
      }),
    }).then((res) => res.json())
      .then((data) => { 
        if (data.success) {
          setPDLTrigger(true);
        }
      })
  }

  useEffect(() => {
    if (!deleteClient) return;
    fetch(`${url}/admin/remove`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: approvedcompanydata._id,
        timestamp: new Date(),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) console.log("error deleting!");
        setdeleteClient(false);
      })
      .catch((err) => console.log(err));
  }, [deleteClient]);

  useEffect(() => {
    if (
      approvedcompanydata.category === "Free" &&
      approvedcompanydata.status === "stopCandidates"
    ) {
      setstopcandidates(false);
    }
    if (
      approvedcompanydata.category === "PPH" &&
      approvedcompanydata.status === "In-Active"
    ) {
      setmomatch(false);
    }
    if (
      approvedcompanydata.category === "Subscription" &&
      approvedcompanydata.status === "In-Active"
    ) {
      setmomatch1(false);
    }
  }, [approvedcompanydata]);

  useEffect(() => {
    if (!approveclicked || approvefor === "") return;
    fetch(`${url}/admin/approve`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: approvedcompanydata._id,
        type: approvefor,
        timestamp: new Date(),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setapprovefor("");
          setapproveclicked(false);
          setreload(true);
        } else {
          seterror(true);
        }
      })
      .catch(() => seterror(true));
    return () => {
      setreload(false);
    };
  }, [approvefor, approveclicked, approvedcompanydata, seterror, setreload]);

  useEffect(() => {
    if (!statusclicked) return;
    if (status === "") return;
    fetch(`${url}/admin/update_status`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: approvedcompanydata._id,
        status: status,
        timestamp: new Date(),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setstatus("");
          setstatusclicked(false);
        } else {
          seterror(true);
        }
      })
      .catch(() => seterror(true));
  }, [status, statusclicked, approvedcompanydata, seterror]);

  return (
    <div className="flex justify-center ma4-l ma4-m ma1 mt4">
      <div
        style={{ borderLeft: "2px solid #6EB6FF" }}
        className="c-shadow br2 bg-white w-80-l w-80-m w-90"
      >
        <div className="flex flex-column flex-row-l justify-between items-center-l pa3 h-100">
          <div className="flex flex-column justify-between h-75 flex-1">
            <p className="ma0 f4-l f4-m f5">{`${approvedcompanydata.company} (${approvedcompanydata.client_id})`}</p>
            <p className="ma0 gray f7-l f8-m mt1">
              {approvedcompanydata.email}
            </p>
            <p className="ma0 gray f7-l f8-m mt1">
              {approvedcompanydata.phone}
            </p>
            <p className="ma0 gray f7-l f8-m mt1">
              Jobs Posted: {approvedcompanydata.number_jobs_posted}
            </p>
          </div>
          <div className="flex flex-column items-center justify-between flex-1">
            <button
              style={{ border: "1px solid #6EB6FF", color: "#6EB6FF" }}
              className="c-shadow pointer f8-mo f7-m f6-l w-80 h2 bn link dim br1 ph3 pv2 dib bg-white mt2"
              onClick={() => {
                localStorage.setItem(
                  "a_c_id",
                  JSON.stringify(approvedcompanydata._id),
                );
                localStorage.setItem(
                  "a_c_email",
                  JSON.stringify(approvedcompanydata.email),
                );
                history.push("/credits/manage");
              }}
            >
              Credits Page
            </button>
          </div>
          <div
            style={{ gap: "12px" }}
            className="flex flex-column-l mv3 flex-row center-l flex-1 items-center"
          >
            {free ? (
              <div
                onClick={() => {
                  setdeleteClient(true);
                }}
                className={`flex-1 mr2-m w-80`}
              >
                <button
                  style={{ background: "#6EB6FF" }}
                  className="c-shadow pointer f8-mo f7-m f6-l w-100 h2 bn link dim br1 ph3 pv2 dib white"
                  >
                  Delete Client
                </button>
              </div>
            ) : (
              <div
                onClick={() => triggerPDLToggle()}
                className={`flex-1 mr2-m w-80`}
              >
                <button
                  style={{ background: "#6EB6FF" }}
                  className="c-shadow pointer f8-mo f7-m f6-l w-100 h2 bn link dim br1 ph3 pv2 dib white"
                  >
                  {`${PDLTrigger || approvedcompanydata?.isPdl ? 'Disable' : 'Enable' } PDL`}
                </button>
              </div>
            )}
            <div
              onClick={() => {
                setapproveclicked(true);
                setapprovefor(
                  approvedcompanydata.category === "Subscription" ||
                    approvedcompanydata.category === "PPH"
                    ? "Free"
                    : "PPH",
                );
              }}
              className="flex-1 w-80"
            >
              <button
                style={{ background: "#6EB6FF" }}
                className="c-shadow pointer f8-mo f7-m f6-l w-100 h2 bn link dim br1 ph3 pv2 dib white"
              >
                Transfer to{" "}
                {approvedcompanydata.category === "Subscription" ||
                approvedcompanydata.category === "PPH"
                  ? "Free"
                  : "Paid"}
              </button>
            </div>
          </div>
          <div
            style={{ columnGap: "12px" }}
            className="flex flex-column-l justify-between h-75 flex-1 mt3-m"
          >
            <div
              onClick={() => {
                localStorage.setItem(
                  "a_c_id",
                  JSON.stringify(approvedcompanydata._id),
                );
                history.push("/Jobs");
              }}
              className="c-shadow w-80 ml-auto mr2-m mb2-l"
            >
              <Button
                style={{ borderColor: "#6EB6FF", color: "#6EB6FF" }}
                className="w-100"
                variant="outlined"
              >
                View <span className="hide-mo ml1">Details</span>
              </Button>
            </div>
            <button
              onClick={() => {
                setmomatch((prev) => !prev);
                setstatusclicked(true);
                momatch ? setstatus("In-Active") : setstatus("Approved");
              }}
              style={{
                borderColor: momatch ? "red" : "green",
                color: momatch ? "red" : "green",
              }}
              className={`${
                !free && pps ? "" : "hide"
              } bg-white pointer ml-auto ac-btn c-shadow w-80 h2-l bn link dim br2 ph3 pv-l pv1 dib`}
            >
              <div className="btnic flex f8-m f7-l items-center justify-center">
                {momatch ? <HighlightOffIcon /> : <DoneOutlineIcon />}
                <span className="ml2">
                  {momatch ? "Stop" : "Start"} Momatch
                </span>
              </div>
            </button>
            <button
              onClick={() => {
                setstopcandidates(!stopcandidates);
                setstatusclicked(true);
                stopcandidates
                  ? setstatus("stopCandidates")
                  : setstatus("Approved");
              }}
              style={{
                borderColor: stopcandidates ? "red" : "green",
                color: stopcandidates ? "red" : "green",
              }}
              className={`${
                free ? "" : "hide"
              } bg-white pointer ml-auto ac-btn c-shadow w-80 h2-l bn link dim br2 ph3 pv-l pv1 dib`}
            >
              <div className="btnic flex f7-l f8-m items-center justify-center">
                {stopcandidates ? <HighlightOffIcon /> : <DoneOutlineIcon />}
                <span className="ml2">
                  {stopcandidates ? "Stop" : "Start"} Candidates
                </span>
              </div>
            </button>
            <button
              onClick={() => {
                setmomatch1((prev) => !prev);
                setstatusclicked(true);
                momatch1 ? setstatus("In-Active") : setstatus("Approved");
              }}
              style={{
                borderColor: momatch1 ? "red" : "green",
                color: momatch1 ? "red" : "green",
              }}
              className={`${
                !free && !pps ? "" : "hide"
              } bg-white pointer ml-auto ac-btn c-shadow w-80 h2-l bn link dim br2 ph3 pv-l pv1 dib`}
            >
              <div className="btnic flex f8-m f7-l items-center justify-center">
                {momatch1 ? <HighlightOffIcon /> : <DoneOutlineIcon />}
                <span className="ml2">
                  {momatch1 ? "Stop" : "Start"} Momatch
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
