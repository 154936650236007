import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import { url } from "../../util/data/static-data";
import "tachyons";
import { useHistory } from "react-router-dom";

function Login({ setlog, setid }) {
  const [username, setusername] = useState("");
  const [pass, setpass] = useState("");
  const [click, setclick] = useState(false);
  const [error, seterror] = useState({ val: false, msg: "" });

  const history = useHistory();

  useEffect(() => {
    if (username === "" && pass === "") return;
    fetch(`${url}/admin/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: username,
        hashedPwd: pass,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setlog(true);
          setid(data.admin_id);
          localStorage.setItem("logstate", JSON.stringify(true));
          localStorage.setItem("id", JSON.stringify(data.admin_id));
          history.push("/Dashboard");
          setclick(false);
          localStorage.setItem("a_j_token", JSON.stringify(data.token));
        } else {
          seterror({ val: true, msg: data.msg });
          setusername("");
          setpass("");
          setclick(false);
        }
      })
      .catch(() => {
        setclick(false);
        seterror({ val: true, msg: "Something went Wrong!" });
      }); // eslint-disable-next-line
  }, [click]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setclick(true);
    }
  };

  return (
    <div className="flex w-100 flex-column items-center justify-center vh-75">
      <p className="gray tc f3">Moyyn Admin Login</p>
      <div className="c-shadow bg-white pa5-l pa5-m pa4 w-50-l w-60-m w-90">
        <div className="mb4 center w-80-l w-80-m w-100">
          <TextField
            name="login_id"
            label="Username"
            value={username}
            onChange={(e) => setusername(e.target.value)}
            onKeyDown={handleKeyDown}
            variant="outlined"
            className={`w-100`}
          />
        </div>
        <div className="mb4 center w-80-l w-80-m w-100">
          <TextField
            name="passwd"
            label="Password"
            type="password"
            value={pass}
            onChange={(e) => setpass(e.target.value)}
            onKeyDown={handleKeyDown}
            variant="outlined"
            className={`w-100`}
          />
        </div>
        <div className="flex justify-center">
          <Button
            onClick={() => setclick(true)}
            className="dim w-30-l w-35-m w-50"
            style={{ backgroundColor: "#6EB6FF", color: "white" }}
            variant="contained"
          >
            Login
          </Button>
        </div>
        <div className={`${error.val ? "" : "hide"} mt4`}>
          <Alert severity="error">{error.msg}</Alert>
        </div>
      </div>
    </div>
  );
}

export default Login;
