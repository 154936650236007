import React, { useEffect, useState } from "react";
import JobCard from "./Job";
import Company from "./CompanyCard";
import ChevronLeftTwoToneIcon from "@mui/icons-material/ChevronLeftTwoTone";
import { useHistory } from "react-router-dom";
import Loading from "../Loading/Loading";
import { k_url } from "../../util/data/static-data";

export default function Joblist({ id, seterror }) {
  const history = useHistory();

  const [cid] = useState(JSON.parse(localStorage.getItem("a_c_id")));
  const [data, setdata] = useState({});
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    if (!cid) return;
    setisloading(true);
    fetch(`${k_url}/company/find`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: cid,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setdata(data.result);
          setisloading(false);
        } else {
          setisloading(false);
          seterror(true);
        }
      })
      .catch(() => {
        setisloading(false);
        seterror(true);
      });
  }, [cid, seterror]);

  if (isloading) {
    return (
      <div className="flex w-100 items-center justify-center bg-white ma3 br2 vh-75">
        <Loading text="Loading Client Jobs..." />
      </div>
    );
  }

  return (
    <div className="w-100">
      <div className="flex items-center justify-between">
        <ChevronLeftTwoToneIcon
          onClick={() => history.push("/Dashboard")}
          className="mt4 dim pointer"
        />
        <p className="gray tc f2-l f2-m f3 mt4 ma0 mb0 self-center ml6 mr-auto">
          Client Moyyn Dashboard
        </p>
      </div>
      <Company company={data} />
      <div className="ma4-l ma4-m ma0 mt4">
        <p className="gray tl f5-l f5-m f6 center w-80-l w-80-m w-90 ma0">
          Jobs Posted
        </p>
      </div>
      <div className="w-80-l w-80-m w-90 flex center flex-column ma-2">
        {data.Jobs === undefined || data.Jobs.length <= 0 ? (
          <div
            style={{ height: "70vh" }}
            className="flex justify-center items-center"
          >
            <p className="ma0 f3-l f4-m f6 gray tc">
              No, Job posted yet please post a job
            </p>
          </div>
        ) : (
          data.Jobs.map((jd, id) => <JobCard job={jd} key={id} />)
        )}
      </div>
    </div>
  );
}
