import React from "react";
import ApprovedCompany from "./ApprovedCompany";

export default function Approvedlist({
  approvedclientlist,
  pps,
  free,
  seterror,
  setreload,
}) {
  approvedclientlist =
    approvedclientlist !== undefined
      ? free
        ? approvedclientlist.filter((a) => a.category === "Free")
        : approvedclientlist.filter((a) => a.category === "PPH" || a.category === "Subscription")
      : [];
  return (
    <div>
      {approvedclientlist.length > 0 ? (
        approvedclientlist.map((approvedclient, id) => {
          return (
            <ApprovedCompany
              key={id}
              approvedcompanydata={approvedclient}
              free={free}
              pps={pps}
              seterror={seterror}
              setreload={setreload}
            />
          );
        })
      ) : (
        <div className="flex mt4 justify-center items-center">
          <p className="ma0 f3-l f4-m f6 gray tc">No company approved</p>
        </div>
      )}
    </div>
  );
}
