import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { url } from "../../util/data/static-data";

function Company({ companydata, setreload, seterror }) {
  const [approveclicked, setapproveclicked] = useState(false);
  const [approvefor, setapprovefor] = useState("");

  useEffect(() => {
    if (!approveclicked) return;
    if (approvefor === "") return;
    fetch(`${url}/admin/approve`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        company_id: companydata._id,
        type: approvefor,
        timestamp: new Date(),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setreload(true);
          setapprovefor("");
          setapproveclicked(false);
        } else {
          seterror(true);
        }
      })
      .catch(() => seterror(true));
  }, [approvefor, approveclicked, companydata, setreload, seterror]);

  return (
    <div className="flex justify-center ma4-l ma4-m ma1 mt4">
      <div
        style={{ borderLeft: "2px solid #6EB6FF" }}
        className="c-shadow br2 bg-white w-80-l w-80-m w-90 ch-2 ch-2-mo"
      >
        <div className="flex justify-between items-center pa3 h-100">
          <div className="flex flex-column justify-between h-75 flex-1">
            <p className="ma0 f4-l f4-m f5">{`${companydata.company} (${companydata.client_id})`}</p>
            <p className="ma0 gray f7-l f7-m f8-mo">{companydata.email}</p>
            <p className="ma0 gray f7-l f7-m f8-mo">{companydata.phone}</p>
          </div>
          <div className="flex flex-column justify-between h-75 flex-1 hide-mo">
            <p className="ma0 gray f5-l f6-m f7">{companydata.name}</p>
            <p className="ma0 gray f5-l f6-m f7">{companydata.website}</p>
          </div>
          <div className="flex flex-column justify-between h-75 flex-1">
            <p className="ma0 tr gray f8">
              <span className="hide-mo">Registered on: </span>7th Nov, 2020
            </p>
            <div className="flex flex-column">
              <p className="ma0 self-center ml4 ml0-l ml0-m mb3-l mb3-m mb2 gray f6-l f7-m f7 hide-mo">
                Approve For
              </p>
              <div className="flex flex-row-l flex-row-m flex-column justify-between">
                <div
                  onClick={() => {
                    setapproveclicked(true);
                    setapprovefor("Free");
                  }}
                  className="flex-1 c-shadow ac-btn"
                >
                  <Button
                    style={{ borderColor: "#6EB6FF", color: "#6EB6FF" }}
                    className="w-100"
                    variant="outlined"
                  >
                    Free
                  </Button>
                </div>
                <div className="w1 hcp"></div>
                <div
                  onClick={() => {
                    setapproveclicked(true);
                    setapprovefor("PPH");
                  }}
                  className="flex-1 c-shadow ac-btn"
                >
                  <Button
                    style={{ borderColor: "#6EB6FF", color: "#6EB6FF" }}
                    className="w-100"
                    variant="outlined"
                  >
                    Paid
                  </Button>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
